import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ReCAPTCHA from "react-google-recaptcha"; 
import ListContacts from '../ListContacts';

interface IWorkWithUs{
    name?: string;
    area?: string;
    presentation?: string;
    file?: File;    
}

const Footer = () => {
  const styleTitle = {
      fontFamily: 'Roboto',
      letterSpacing: '0.05rem'
  };
  const styleP = {
      fontFamily: 'Open Sans'
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [emailLearnMore, setEmailLearnMore] = useState<string>();
  const [workWithUs, setWorkWithUs] = useState<IWorkWithUs>();
  const [recaptchaValidated, setRechaptchaValidated] = useState<boolean>(false);
  return (
    <footer style={{background: '#424242', color: '#FFF', padding: '1rem'}}>
    <div className="container">
        <div className="row">
            <div className="col-md-4">
                <h5 style={styleTitle}> {i18n.t('components.footer.sobre.title').toString()} </h5>
                <p style={styleP}> {i18n.t('components.footer.sobre.listItem.text.text').toString()} </p>
            </div>
            <div className="col-md-4">
                <h5 style={styleTitle}>{i18n.t('components.footer.contato.title').toString()}</h5>
                <ListContacts color="#FFF" paddingTop=".2rem" />
            </div>
            <div className="col-md-4">
                <h5 style={styleTitle}>{i18n.t('components.footer.saibaMais.title').toString()}</h5>
                <p style={{fontFamily: 'Open Sans', fontSize: '12px'}}> {i18n.t('components.footer.saibaMais.listItem.saiba.text').toString()} </p>
                <form 
                    className="row"
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if(!emailLearnMore || !recaptchaValidated)
                            return;
                        const retorno = await api.get(`/formulario/saiba-mais/${emailLearnMore}`);
                        if(retorno.status === 200)
                        {
                            alert(i18n.t('page.contact.alert').toString());
                            setIsOpen( false );
                        }
                    }}>
                    <div className="col-md-10 col-sm-10 col-sx-1">
                        <input 
                            className="form-control" 
                            type="email" 
                            placeholder="E-mail" 
                            value={emailLearnMore}
                            onChange={(e) => {
                                setEmailLearnMore(e.target.value);
                            }}
                            />
                    </div>
                    <div className="col-md-1 col-sm-1 col-sx-1">
                        <button type="submit" className="btn btn-secondary" disabled={!recaptchaValidated}>OK</button>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <ReCAPTCHA
                            sitekey="6LcsOkAUAAAAAHtKlobwzh0hhl-DgToUhC-95ZAJ"
                            onChange={(v) => { setRechaptchaValidated(true) }}
                        />
                    </div>
                </form>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <h5 style={{...styleTitle, cursor: 'pointer'}} onClick={() => { setIsOpen(true); }}>{i18n.t('components.footer.trabalhe').toString()}</h5>
            </div>
        </div>
        <div className="text-center" style={{position: 'relative', bottom: '0'}}>
            <label style={{fontFamily: 'Open Sans', fontSize: '12px'}}> © {new Date().getFullYear()}. Saga Tecnologia</label>
        </div>
    </div>
    <Modal isOpen={isOpen}>
        <ModalHeader toggle={() => { setIsOpen(false) }}>
            {i18n.t('page.home.listProducts.trabalho.trabalhe').toString()}
        </ModalHeader>
        <form onSubmit={async (e) => {
            e.preventDefault();
            if(workWithUs && workWithUs.file && isOpen){
                const formData = new FormData();            
                formData.append("file", workWithUs?.file as any);
                formData.append("name", workWithUs?.name || '');
                formData.append("presentation", workWithUs?.presentation || '');
                formData.append("area", workWithUs?.area || '');
                const retorno =
                await api.post('/formulario/trabalhe-conosco', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }});
                    if(retorno.status === 200)
                    {
                        alert(i18n.t('page.contact.alert').toString());
                        setIsOpen( false );
                    }
            }
        }}>
            <ModalBody>
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <input 
                            className="form-control" 
                            placeholder={i18n.t('page.home.listProducts.trabalho.nome').toString()} 
                            value={workWithUs?.name}
                            required
                            onChange={(e) => {
                                setWorkWithUs({...workWithUs, name: e.target.value})
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <select 
                            className="form-control" 
                            placeholder="Área desejada"
                            required
                            value={workWithUs?.area}
                            onChange={(e) => {
                                setWorkWithUs({...workWithUs, area: e.target.value})
                            }}
                        >
                            <option value="Administrativo">{i18n.t('page.home.listProducts.trabalho.adm').toString()}</option>
                            <option value="Comercial">{i18n.t('page.home.listProducts.trabalho.comercial').toString()}</option>
                            <option value="Desenvolvimento/TI">{i18n.t('page.home.listProducts.trabalho.dev').toString()}</option>
                            <option value="Projetos">{i18n.t('page.home.listProducts.trabalho.projetos').toString()}</option>
                            <option value="Suporte">{i18n.t('page.home.listProducts.trabalho.sup').toString()}</option>
                        </select>
                    </div>
                </div>
                <div className="row" style={{padding: '10px 0 0 0'}}>
                    <div className="col-md-12">
                        <textarea 
                            className="form-control" 
                            placeholder={i18n.t('page.home.listProducts.trabalho.apresentacao').toString()}                             
                            value={workWithUs?.presentation}
                            onChange={(e) => {
                                setWorkWithUs({...workWithUs, presentation: e.target.value})
                            }}
                        />
                    </div>
                </div>
                <div className="row" style={{padding: '10px 0 0 0'}}>                    
                    <div className="col-md-12">
                        <input 
                            type="file" 
                            placeholder={i18n.t('page.home.listProducts.trabalho.curriculo').toString()} 
                            className="form-control" 
                            accept=".pdf" 
                            onChange={(e) => {
                                if(e.target.files)
                                    setWorkWithUs({...workWithUs, file: e.target.files[0]})
                            }}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>   
                <button type="submit" className="btn btn-secondary">{i18n.t('page.contact.listItem.info.btne').toString()}</button>
            </ModalFooter>
        </form>
    </Modal>
    </footer>
  );
}

export default Footer;
