import logo from '../../images/logo.png';
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import Suporte from "../../components/Suporte";
import TopBar from "../../components/TopBar";
import { i18n } from '../../translate/i18n';


const support = () =>{
    return(
        <>
            <TopBar />
            <div className="container">
                <img src={logo} alt="Logo Saga Tecnologia" className="logo" />
                <NavBar />
            </div>
            <div style={{textAlign:"center",margin:"5rem 0 5rem 0" }}>
                <a href="https://servidorseguro.mysuite1.com.br/client/login.php?sl=saga&param=hd_chat_gc_cad_chatdep&cdu=&inf=&sl=saga&lf=&ca=&cr=&idm=&cdu=1&redirect=http://saga.mysuite1.com.br/empresas/saga/central.php" target="_blank" className="btn btn-lg" style={{margin: '0 auto', background: '#752C2D', color: '#FFF', display: 'flex', width: '220px', justifyContent: 'center', alignItems: 'center'}} rel="noreferrer" >{i18n.t('page.products.itens.wmsInv.btn').toString()}</a>
            </div>
            <Suporte/>
            <Footer />
        </>
    )
}
export default support;