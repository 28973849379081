import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import SubTitle from '../../components/SubTitle';
import TopBar from '../../components/TopBar';
import logo from '../../images/logo.png';
import iconOrigem from '../../images/icons/lightbulb.svg';
import iconSync from '../../images/icons/sync.svg';
import iconTruckLoading from '../../images/icons/truck-loading.svg';
import iconRocket from '../../images/icons/rocket.svg';

import quoteHistory from '../../images/quote-historia.jpg';
import sobreOrigem from '../../images/sobre-origem.png';
import sobreDemanda from '../../images/SobreDemanda.png';
import sobreEstrutura from '../../images/SobreEstrutura.png';
import sobreAtua from '../../images/SobreAtualidade.png';
import HistoryItem from './components/HistoryItem';
import { i18n } from '../../translate/i18n';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import suporteIcon from '../../images/suporte.svg';
import users from '../../images/users.svg';
import truck from '../../images/truck.svg';
import clipboard from '../../images/clipboard-list.svg'; 
import Gem from '../../images/Gem.svg';
import eye from '../../images/eye.svg';  
const About = () => {
  const styleIcon = { 
    background: '#752C2D', 
    padding: '.3rem', 
    borderRadius: '16px', 
    marginRight: '.5rem' 
  };
  return (
      <>
      <TopBar/>
      <section>
        <div className="container">
          <img src={logo} alt="Logo - SAGA TECNOLOGIA" className="logo" />        
          <NavBar />
          <div style={{margin: '40px 0'}}>
            <SubTitle text={i18n.t('page.about.sobreNos.title').toString()} />
            <p style={{fontFamily: 'Open Sans' }}> {i18n.t('page.about.sobreNos.listItem.sobre.text').toString()} </p>
          </div>
        </div>
        <div className="container">
          <Row>
            <div className="col-md-4 col-sm-12">
              <i style={styleIcon} >
                  <img src={truck} alt="Suporte" width="20px" height="auto"/>
                </i>
              <span style={{fontSize: '1.1rem', paddingLeft: '.5rem', letterSpacing: '.05rem', fontFamily: 'Roboto', color: '#424242'}}>{i18n.t('page.about.servicos.log').toString()}</span>
              <p style={{fontFamily: 'Open Sans', fontWeight: '300',}}>{i18n.t('page.about.servicos.logsub').toString()}</p>
            </div>
            <div className="col-md-4 col-sm-12">
              <i style={styleIcon} >
                <img src={users} alt="Suporte" width="20px" height="auto"/>
              </i>
              <span style={{fontSize: '1.1rem', paddingLeft: '.5rem', letterSpacing: '.05rem', fontFamily: 'Roboto', color: '#424242'}}>{i18n.t('page.about.servicos.eqp').toString()}</span>
              <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>{i18n.t('page.about.servicos.eqpsub').toString()}</p>
            </div>
            <div className="col-md-4 col-sm-12">
              <i style={styleIcon} >
                <img src={suporteIcon} alt="Suporte" width="20px" height="auto"/>
              </i>
              <span style={{fontSize: '1.1rem', paddingLeft: '.5rem', letterSpacing: '.05rem', fontFamily: 'Roboto', color: '#424242'}}>{i18n.t('page.about.servicos.sup').toString()}</span>
              <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>{i18n.t('page.about.servicos.supsub').toString()}</p>
            </div>
            <div className="col-md-4 col-sm-12">
              <i style={styleIcon} >
                  <img src={clipboard} alt="Suporte" width="20px" height="auto"/>
                </i>
              <span style={{fontSize: '1.1rem', paddingLeft: '.5rem', letterSpacing: '.05rem', fontFamily: 'Roboto', color: '#424242'}}>{i18n.t('page.about.servicos.mis').toString()}</span>
              <p style={{fontFamily: 'Open Sans', fontWeight: '300',}}>{i18n.t('page.about.servicos.missub').toString()}</p>
            </div>
            <div className="col-md-4 col-sm-12">
              <i style={styleIcon} >
                  <img src={eye} alt="Suporte" width="20px" height="auto"/>
                </i>
              <span style={{fontSize: '1.1rem', paddingLeft: '.5rem', letterSpacing: '.05rem', fontFamily: 'Roboto', color: '#424242'}}>{i18n.t('page.about.servicos.vis').toString()}</span>
              <p style={{fontFamily: 'Open Sans', fontWeight: '300',}}>{i18n.t('page.about.servicos.vissub').toString()}</p>
            </div>
            <div className="col-md-4 col-sm-12">
              <i style={styleIcon} >
                  <img src={ Gem} alt="Suporte" width="20px" height="auto"/>
                </i>
              <span style={{fontSize: '1.1rem', paddingLeft: '.5rem', letterSpacing: '.05rem', fontFamily: 'Roboto', color: '#424242'}}>{i18n.t('page.about.servicos.val').toString()}</span>
              <p style={{fontFamily: 'Open Sans', fontWeight: '300',}}>{i18n.t('page.about.servicos.valsub').toString()}</p>
            </div>
            
          </Row>
          
        </div>
        
      </section>  
      <section style={{background: `url(${quoteHistory})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '200px', paddingTop: '75px'}}>
        <SubTitle text={i18n.t('page.about.sobreNos.listItem.sobre.sub').toString()}  color="#FFF" />
      </section>
      <section className="container">
        <HistoryItem icon={iconOrigem} title={i18n.t('page.about.list.listItem.orig.text').toString()} img={sobreOrigem} text={i18n.t('page.about.list.listItem.orig.sub').toString()} subText={i18n.t('page.about.list.listItem.orig.subText').toString()} />
        <HistoryItem icon={iconSync} title={i18n.t('page.about.list.listItem.dema.text').toString()} img={sobreDemanda} text={i18n.t('page.about.list.listItem.dema.sub').toString()} />
        <HistoryItem icon={iconTruckLoading} title={i18n.t('page.about.list.listItem.estr.text').toString()} img={sobreEstrutura} text={i18n.t('page.about.list.listItem.estr.sub').toString()} />
        <HistoryItem icon={iconRocket} title={i18n.t('page.about.list.listItem.atua.text').toString()} img={sobreAtua} text={i18n.t('page.about.list.listItem.atua.sub').toString()} />
      </section>
      <section style={{height: '200px', padding: '20px 0 40px 0',}}>
        <SubTitle text={i18n.t('page.about.btn.listItem.btnContato.text').toString()} color="#424242" />
        
        <Link to="/contact" className="btn btn-lg" style={{margin: '20px auto', background: '#752C2D', color: '#FFF', display: 'flex', width: '200px', justifyContent: 'center', alignItems: 'center'}} >{i18n.t('page.about.btn.listItem.btnContato.sub').toString()}</Link>
      </section>
      <Footer />
      </>
  );
}

export default About;
