import React, { useState } from 'react';
import whatsappIcon from '../../images/whatsapp.svg';
import linkedInIcon from '../../images/linkedIn.svg';
import bullhornIcon from '../../images/bullhorn.svg';
import faceIcon from '../../images/facebook.svg';
import instIcon from '../../images/inst.svg';
import tv from '../../images/tv.svg';
import suporteIcon from '../../images/suporte.svg';
import ptBr from '../../images/pt-br.svg';
//import es from '../../images/es.png';
import en from '../../images/en-us.svg';
import { i18n } from '../../translate/i18n';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,  faPhone } from '@fortawesome/free-solid-svg-icons';

interface IOuvidoria {
  name?: string;
  contact?: string;
  message?: string;
}

const TopBar = () => {
  const alterarZoom = (aumentar: Boolean) => {
    let style = document.body.style as any;
    let zoomAtual = parseInt(style.zoom.replace('%','')) || 100; 
    if(aumentar === true) zoomAtual += 20;
    else zoomAtual -= 20;
  
    if(zoomAtual !== 200 && zoomAtual !== 60)
    style.zoom = `${zoomAtual}%`;
  }
  
  const alterLanguage = (currentLanguage: string): void => {
    window.localStorage.setItem('i18nextLng', currentLanguage);
    window.location.reload();
  }
  const styleIcon = { 
    background: '#752C2D', 
    padding: '.3rem', 
    borderRadius: '16px', 
    marginRight: '.5rem' 
  };

  const styleIconLanguages = {
    margin: '0 .3rem',
    cursor: 'pointer'
  }

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ouvidoria, setOuvidoria] = useState<IOuvidoria>();
 

  return (
      <div style={{
             background: '#424242', 
             color: '#fff', padding: '.6rem'
          }}
      >
        <div className="container" style={{ display: 'flex', justifyContent: 'space-between'}}>
          <div id="top-bar-icons">
            {
            <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5531993153709" target="_blank" rel="noreferrer">
              <i style={styleIcon}>
                <img src={whatsappIcon} alt="Whatsapp" width="18px" height="30px"/>
              </i>
            </a>
            }
            <a href="https://www.instagram.com/saga.tecnologia/?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
              <i style={styleIcon}>
                <img src={instIcon} alt="inst" width="18px" height="30px"/>
              </i>
            </a>
            <a href="https://www.linkedin.com/company/saga-tecnologia/" target="_blank" rel="noreferrer">
              <i style={styleIcon}>
                <img src={linkedInIcon} alt="LinkedIn" width="18px" height="30px"/>
              </i>
            </a>
            <span style={{cursor: 'pointer'}} onClick={() => { setIsOpen(true)}}>
              <i style={styleIcon}>
                <img src={bullhornIcon} alt="Bulhorn" width="18px" height="30px"/>
              </i>
            </span>
            <a href="https://servidorseguro.mysuite1.com.br/client/login.php?sl=saga&param=hd_chat_gc_cad_chatdep&cdu=&inf=&sl=saga&lf=&ca=&cr=&idm=&cdu=1&redirect=http://saga.mysuite1.com.br/empresas/saga/central.php" target="_blank" rel="noreferrer">
              <i style={styleIcon}>
                <img src={suporteIcon} alt="Suporte" width="18px" height="30px"/>
              </i>
            </a>
            <Link style={{color:'#424242'}} rel="noreferrer" to="/internal">
            <i style={styleIcon} >
                <img src={tv} alt="Suporte" width="18px" height="30px"/>
              </i>
            </Link>
            
          </div>
          <div>
          
           <a className='top-bar-contacts' href="tel:5503145010000" style={{color:  '#F5F5F5',borderRight: '1px solid #777',padding: '0 10px', fontFamily: 'Open Sans', fontSize: '12px'}} target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faPhone} /> {i18n.t('components.footer.contato.listItem.tele.sub').toString()} </a>
           <a className='top-bar-contacts' href="mailto:sacom@sasistemas.com.br" style={{color: '#F5F5F5',borderRight: '1px solid #777',padding: '0 10px', fontFamily: 'Open Sans', fontSize: '12px' }} target="_blank" rel="noreferrer">  <FontAwesomeIcon icon={faEnvelope} /> {i18n.t('components.footer.contato.listItem.email.sub').toString()}</a>

            <img src={ptBr} alt="Português" style={{...styleIconLanguages, marginLeft:"10px"}} onClick={() => {alterLanguage('pt-BR')}} width="20" />
            <img src={en} alt="English" style={styleIconLanguages} onClick={() => {alterLanguage('en')}} width="20" />
            <span style={{marginLeft: '10px',borderLeft: '1px solid #777',paddingLeft: '10px', fontFamily: 'Open Sans', fontSize: '12px'}}> {i18n.t('components.topbar.Acessibilidade').toString()} </span>
            <button onClick={() => {alterarZoom(true)}} className="btn" style={{color:'#fff', padding: '0 5px', fontSize: '14px'}}> A+ </button>
            <button  onClick={() => {alterarZoom(false)} } className="btn" style={{color:'#fff', padding: '0 5px', fontSize: '14px'}}> A- </button>
        
            {/*<img src={es} alt="Espanhol" style={styleIconLanguages} />*/}
          </div>
        </div>
        <Modal isOpen={isOpen}>
          <ModalHeader toggle={() => { setIsOpen(false) }}>
          {i18n.t('page.ouvidoria.title').toString()}
          </ModalHeader>
          <form onSubmit={async (e) => {
            e.preventDefault();
            if(isOpen && ouvidoria){
              const retorno = await api.post('/formulario/ouvidoria', ouvidoria);
              if(retorno.status === 200)
              {
                  alert(i18n.t('page.contact.alert').toString());
                  setIsOpen( false );
              }
            }
          }}>
            <ModalBody>
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <input 
                    className="form-control" 
                    placeholder={i18n.t('page.ouvidoria.name').toString()}
                    value={ouvidoria?.name}
                    required
                    onChange={(e) => {
                      setOuvidoria({...ouvidoria, name: e.target.value})
                    }}  
                  />
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <input 
                    className="form-control" 
                    placeholder={i18n.t('page.ouvidoria.contato').toString()}
                    value={ouvidoria?.contact}
                    required
                    onChange={(e) => {
                      setOuvidoria({...ouvidoria, contact: e.target.value})
                    }}
                  />
                </div>
              </div>
              <div className="row" style={{padding: '10px 0 0 0'}}>
                <div className="col-md-12">
                  <textarea 
                    className="form-control" 
                    placeholder={i18n.t('page.ouvidoria.mensagem').toString()} 
                    value={ouvidoria?.message}
                    required
                    onChange={(e) => {
                      setOuvidoria({...ouvidoria, message: e.target.value})
                    }}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button type="submit" className="btn btn-secondary">{i18n.t('page.contact.listItem.info.btne').toString()}</button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
  );
}

export default TopBar;
