import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SubTitle from "../../components/SubTitle";
import TopBar from "../../components/TopBar";
import background from "../../images/bg.jpg";
import logoWrite from "../../images/logoBranca.png";
import CardProducts from "./components/CardProducts";
import advancedImage from "../../images/saga-advanced.png";
import cloudImage from "../../images/saga-cloud.png";
import lightImage from "../../images/saga-light.png";

//import serviceImage from '../../images/saga-service.png';
//import voiceImage from '../../images/saga-voice.png';

import webImage from "../../images/saga-web.png";
import logoGranfino from "../../images/clients/granfino.png";
import bgSectionWms from "../../images/bg-section-wms.jpg";
import iconPremiacoes from "../../images/premio.png";
import iconCertificacoes from "../../images/certificacao.png";
import { i18n } from "../../translate/i18n";
import CheckBoxSagaWms from "./components/CheckBoxSagaWms";
import { Link } from "react-router-dom";
import HighLight from "./components/Highlight";
import {
  faCalendarAlt,
  faUsers,
  faCheck,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
//LOGOS DE CLIENTE
import client0 from "../../images/clients/3coracoes.png";
import client1 from "../../images/clients/adar.png";
import client2 from "../../images/clients/bigLar.png";
import client4 from "../../images/clients/casadiconti.png";
import client5 from "../../images/clients/cipatex.png";
import client8 from "../../images/clients/edRecord.png";
import client9 from "../../images/clients/embelleze.png";
import client10 from "../../images/clients/granfino.png";
import client11 from "../../images/clients/HidrauMaquinas.png";
import client12 from "../../images/clients/iconeLogistica.png";
import client13 from "../../images/clients/img-2.png";
import client14 from "../../images/clients/img-4.png";
import client16 from "../../images/clients/logshore.png";
import client17 from "../../images/clients/lojasCem.png";
import client18 from "../../images/clients/Marelli.png";
import client19 from "../../images/clients/motociclo.png";
import client20 from "../../images/clients/natcofarma.png";
import client21 from "../../images/clients/nutrimental.png";
import client22 from "../../images/clients/petronas.png";
import client23 from "../../images/clients/ppDistribuidora.png";
import client24 from "../../images/clients/prometeon.png";
import client25 from "../../images/clients/rei.png";
import client26 from "../../images/clients/royalCanin.png";
import client27 from "../../images/clients/sitCar.png";
import client28 from "../../images/clients/smartLog.png";
import client29 from "../../images/clients/tagServicos.png";
import client30 from "../../images/clients/tci.png";
import client31 from "../../images/clients/tecfil.png";
import client32 from "../../images/clients/TLSLogistica.png";
import client33 from "../../images/clients/toolsword.png";
import client34 from "../../images/clients/universal.png";
import client35 from "../../images/clients/wComex.png";
import client36 from "../../images/clients/gruposeculus.png";
import ClientComment from "./components/ClientComment";

const Home = () => {
  const styleQuoteText = {
    background: "#752C2D",
    minHeight: "190px",
    padding: ".5rem 0",
  };

  const location = useLocation();

  const styleCommentSelecion = {
    background: "#424242",
    borderRadius: "1rem",
    height: "20px",
    width: "20px",
    padding: "0",
    margin: "0 7px",
    cursor: "pointer",
  };
  const clients = [
    client0,
    client1,
    client2,
    client4,
    client5,
    client8,
    client9,
    client10,
    client11,
    client12,
    client13,
    client14,
    client16,
    client17,
    client18,
    client19,
    client20,
    client21,
    client22,
    client23,
    client24,
    client25,
    client26,
    client27,
    client28,
    client29,
    client30,
    client31,
    client32,
    client33,
    client34,
    client35,
    client36,
  ];
  const showComment = (id: string) => {
    const comments = document.getElementsByClassName("comment-client");
    for (let i = 1; i <= comments.length; i++) {
      const comment = document.getElementById("comment-" + i);
      if (comment) comment.style.display = "none";
      const selComment = document.getElementById("sel-comment-" + i);
      if (selComment) selComment.style.background = "#424242";
    }
    const comment = document.getElementById("comment-" + id);
    if (comment) comment.style.display = "block";
    const selComment = document.getElementById("sel-comment-" + id);
    if (selComment) selComment.style.background = "#752c2d";
  };

  useEffect(() => {
    const scrollComponent = () => {
      const root: any = document.getElementById("list-clients");
      const marqueeContent: any = document.querySelector("ul.marquee-content");
      const targetSection = location.hash.substr(1);
     const section = document.getElementById(targetSection);
      if (marqueeContent) {
        root.style.setProperty(
          "--marquee-elements",
          marqueeContent.children.length
        );

        for (let i = 0; i < clients.length; i++) {
          marqueeContent.appendChild(
            marqueeContent.children[i].cloneNode(true)
          );
        }
        if (section) {
          // Rolar suavemente até a seção
          window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth'
          });
        }
        
      }
    };

    scrollComponent();
  }, [location.hash]);

  return (
    <>
      <TopBar />
      <section
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          minHeight: "85vh",
        }}
      >
        <div className="container">
          <img src={logoWrite} alt="Logo - SAGA TECNOLOGIA" className="logo" />
          <NavBar color="#FFFFFF" />
          <h1 className="slogan-home">
            {i18n.t("page.home.slogan").toString()}
          </h1>
        </div>
      </section>
      <section className="container" style={{ margin: "40px auto" }}>
        <SubTitle text={i18n.t("page.home.listProducts.subTitle")} />
        <div className="row" style={{ marginTop: "20px" }}>
          <CardProducts
            url="wms-advanced"
            image={advancedImage}
            title={i18n.t("page.home.listProducts.advanced.title")}
            text={i18n.t("page.home.listProducts.advanced.text")}
          />
          <CardProducts
            url="wms-light"
            image={lightImage}
            title={i18n.t("page.home.listProducts.light.title")}
            text={i18n.t("page.home.listProducts.light.text")}
          />
          <CardProducts
            url="wms-cloud"
            image={cloudImage}
            title={i18n.t("page.home.listProducts.cloud.title")}
            text={i18n.t("page.home.listProducts.cloud.text")}
          />
          <CardProducts
            url="wcs"
            image={webImage}
            title={i18n.t("page.home.listProducts.wcs.title")}
            text={i18n.t("page.home.listProducts.wcs.text")}
          />
        </div>
      </section>
      <section>
        <div className="row m-0">
          <div
            className="col-md-6"
            style={{
              background: `url(${bgSectionWms})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "420px",
            }}
          ></div>
          <div
            className="col-md-6"
            style={{ padding: "1rem 0", background: "#F5F5F5" }}
          >
            <SubTitle text={i18n.t("page.home.sectionWms.title")} />
            <div className="col-md-12">
              <CheckBoxSagaWms
                text={i18n.t("page.home.sectionWms.listItem.convoca.text")}
              />
              <CheckBoxSagaWms
                text={i18n.t(
                  "page.home.sectionWms.listItem.rastreabilidade.text"
                )}
              />
              <CheckBoxSagaWms
                text={i18n.t(
                  "page.home.sectionWms.listItem.produtividade.text"
                )}
              />
              <CheckBoxSagaWms
                text={i18n.t("page.home.sectionWms.listItem.controle.text")}
              />
              <CheckBoxSagaWms
                text={i18n.t("page.home.sectionWms.listItem.reducao.text")}
              />
              <Link
                to="/products/wms-advanced"
                className="btn btn-lg"
                style={{
                  margin: "0 auto",
                  background: "#752C2D",
                  color: "#FFF",
                  display: "flex",
                  width: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {i18n.t("page.home.sectionWms.btnGoToAction").toString()}
              </Link>
              <Link to="/#clientes"></Link>
            </div>
          </div>
        </div>
      </section>
      <section  id="clientes"style={{ margin: "40px 0" }}>
        <SubTitle text={i18n.t("page.home.alguns")} />
        <div className="container">
          <div className="marquee">
            <ul id="list-clients" className="marquee-content">
              {clients.map((client) => {
                return (
                  <li>
                    <img
                      src={client}
                      alt="Cliente SAGA TECNOLOGIA"
                      style={{
                        display: "inline",
                        margin: "0 0.9rem",
                        padding: "4px",
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
      <section style={styleQuoteText}>
        <div className="container">
          <div className="row" style={{ padding: "2rem 0" }}>
            <HighLight
              icon={faCalendarAlt}
              title={i18n.t("page.home.suport.listItem.tempo.text").toString()}
              subTitle={i18n
                .t("page.home.suport.listItem.tempo.subTi")
                .toString()}
            />
            <HighLight
              icon={faUsers}
              title={i18n.t("page.home.suport.listItem.sup.text").toString()}
              subTitle={i18n
                .t("page.home.suport.listItem.sup.subTi")
                .toString()}
            />
            <HighLight
              icon={faCheck}
              title={i18n
                .t("page.home.suport.listItem.projetos.text")
                .toString()}
              subTitle={i18n
                .t("page.home.suport.listItem.projetos.subTi")
                .toString()}
            />
            <HighLight
              icon={faHeadset}
              title={i18n
                .t("page.home.suport.listItem.parceiros.text")
                .toString()}
              subTitle={i18n
                .t("page.home.suport.listItem.parceiros.subTi")
                .toString()}
            />
          </div>
        </div>
      </section>
      <section style={{ margin: "40px 0", textAlign: "center" }}>
        <div className="container">
          <SubTitle text={i18n.t("page.home.client.title")} />
          <ClientComment
            image={logoGranfino}
            comment={i18n.t("page.home.client.listItem.granfino.text")}
            commentedBy={i18n.t("page.home.client.listItem.granfino.subTi")}
            id="comment-1"
            display="block"
          />
          <ClientComment
            image={client17}
            comment={i18n.t("page.home.client.listItem.cem.text")}
            commentedBy={i18n.t("page.home.client.listItem.cem.subTi")}
            id="comment-2"
          />
          <ClientComment
            image={client28}
            comment={i18n.t("page.home.client.listItem.smart.text")}
            commentedBy={i18n.t("page.home.client.listItem.smart.subTi")}
            id="comment-3"
          />
          <div
            className="row text-center"
            style={{ margin: "0 auto", display: "inline-flex" }}
          >
            <span
              id="sel-comment-1"
              style={{ ...styleCommentSelecion, background: "#752C2D" }}
              onClick={() => {
                showComment("1");
              }}
            ></span>
            <span
              id="sel-comment-2"
              style={styleCommentSelecion}
              onClick={() => {
                showComment("2");
              }}
            ></span>
            <span
              id="sel-comment-3"
              style={styleCommentSelecion}
              onClick={() => {
                showComment("3");
              }}
            ></span>
          </div>
        </div>
      </section>
      <section style={styleQuoteText}>
        <div className=" container">
          <div className="row">
            <div className="col-md-8 col-sm-12 col-xs-12">
              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "Roboto",
                  color: "#FFF",
                }}
              >
                {i18n.t("page.home.award.title").toString()}
              </h6>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-xs-12">
                  <img
                    src={iconPremiacoes}
                    alt="Premiações SAGA TÉCNOLOGIA"
                    style={{ marginTop: "2rem", textAlign: "center" }}
                  />
                </div>
                <div className="col-md-11 col-sm-12 col-xs-12">
                  <ul
                    style={{
                      margin: "0",
                      listStyle: "none",
                      color: "#FFF",
                      fontFamily: "Open Sans",
                    }}
                  >
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.award.listItem.first.text")
                        .toString()}{" "}
                    </li>
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.award.listItem.second.text")
                        .toString()}{" "}
                    </li>
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.award.listItem.third.text")
                        .toString()}{" "}
                    </li>
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.award.listItem.fourth.text")
                        .toString()}{" "}
                    </li>
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.award.listItem.fifth.text")
                        .toString()}{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
              <h6
                style={{
                  textAlign: "center",
                  fontFamily: "Roboto",
                  color: "#FFF",
                }}
              >
                {" "}
                {i18n.t("page.home.certificate.title").toString()}{" "}
              </h6>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-xs-12">
                  <img
                    src={iconCertificacoes}
                    alt="Certificações SAGA TÉCNOLOGIA"
                    style={{ marginTop: "2rem", textAlign: "center" }}
                  />
                </div>
                <div className="col-md-11 col-sm-12 col-xs-12">
                  <ul
                    style={{
                      margin: "0",
                      listStyle: "none",
                      color: "#FFF",
                      fontFamily: "Open Sans",
                    }}
                  >
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.certificate.listItem.first.text")
                        .toString()}{" "}
                    </li>
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.certificate.listItem.second.text")
                        .toString()}{" "}
                    </li>
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.certificate.listItem.third.text")
                        .toString()}{" "}
                    </li>
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.certificate.listItem.fourth.text")
                        .toString()}{" "}
                    </li>
                    <li>
                      {" "}
                      {i18n
                        .t("page.home.certificate.listItem.fifth.text")
                        .toString()}{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
