import { BrowserRouter as Router, useRoutes, useLocation } from 'react-router-dom';
import About from './pages/about';
import Contact from './pages/contact';
import Support from './pages/support';
import Home from './pages/home';
import Internal from './pages/internal';
import WmsAdvanced from './pages/products/wmsAdvanced';
import Wcs from './pages/products/wcs';
import WmsLight from './pages/products/wmsLight';
import WmsCloud from './pages/products/wmsCloud';
import WmsInv from './pages/products/wmsInv';
import { useEffect } from "react";
import TermPrivacity from './components/TermPrivacity';
import Partners from './pages/partners';

function App() {
  const AppRouter = () => {
    let routes = useRoutes([
      { path: "/", element: <Home /> },
      { path: "/about", element: <About /> },
      { path: "/contact", element: <Contact /> },
      { path: "/support", element: <Support /> },
      { path: "/products/wms-advanced", element: <WmsAdvanced /> },
      { path: "/products/wcs", element: <Wcs /> },
      { path: "/products/wms-cloud", element: <WmsCloud /> },
      { path: "/products/wms-light", element: <WmsLight /> },
      { path: "/products/inv", element: <WmsInv /> },
      { path: "/internal", element: <Internal /> },
      { path: "/partners", element: <Partners/> },
    ]);
    return routes;
  };
              
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

  return (
    <Router>
      <ScrollToTop />
      <AppRouter />
      <TermPrivacity />
    </Router>
  );
}

export default App;
