import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SubTitle from "../../../components/SubTitle";
import TopBar from "../../../components/TopBar"
import logo from '../../../images/logo.png';
import RelatedServices from "../components/RelatedServices";
import img from '../../../images/products/wcs.png';
import iconConvocacaoAtiva from '../../../images/products/convocacao-ativa.png';
import iconFlexibilidade from '../../../images/products/flexibilidade-e-seguranca.png';
import iconRastreabilidade from '../../../images/products/rastreabilidade.svg';
import ItemFeature from "../components/ItemFeature";
import { i18n } from "../../../translate/i18n";
import GoToAction from "../components/GoToAction";

const Wms = () => {
    return(
        <>
            <TopBar/>
            <div className="container">
                <img src={logo} alt="Logo Saga Tecnologia" className="logo" />
                <NavBar />
            </div>
            <section className="container" style={{margin: '40px auto'}}>
                <SubTitle text={i18n.t('page.products.itens.wcs.title').toString()} />
                <div className="row" style={{marginTop: '20px'}}>
                    <div className="col-md-7 col-sm-12">
                        <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>
                        {i18n.t('page.products.itens.wcs.sub').toString()} 
                        </p>
                        <GoToAction />
                    </div>    
                    <div className="col-md-5 col-sm-12">
                        <img src={img} alt="SAGA WMS ADVANCED" width="100%" />
                    </div>
                </div> 
            </section>
            <section style={{background: '#F5F5F5', padding: '10px 0'}}>
                <SubTitle text={i18n.t('page.products.itens.wcs.subtitle').toString()}  />
                <div className="container">
                    <div className="row">
                        <ItemFeature img={iconConvocacaoAtiva} title={i18n.t('page.products.itens.wcs.controle.title').toString()}  text={i18n.t('page.products.itens.wcs.controle.text').toString()} />
                        <ItemFeature img={iconRastreabilidade} title={i18n.t('page.products.itens.wcs.ranking.title').toString()} text={i18n.t('page.products.itens.wcs.ranking.text').toString()} />
                    </div>    
                    <div className="row" >
                        <ItemFeature img={iconFlexibilidade} title={i18n.t('page.products.itens.wcs.mapa.title').toString()} text={i18n.t('page.products.itens.wcs.mapa.text').toString()} />
                    </div>
                </div>
            </section>
            <RelatedServices />
            <Footer />
        </>
    ) 
}

export default Wms;