import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SubTitle from "../../../components/SubTitle";
import TopBar from "../../../components/TopBar";
import logo from '../../../images/logo.png';
import RelatedServices from "../components/RelatedServices";
import img from '../../../images/products/inv.png';
import { i18n } from "../../../translate/i18n";
import GoToAction from "../components/GoToAction";


const wmsInv = () =>{
    return(
        <>
        <TopBar/>
            <div className="container">
                <img src={logo} alt="Logo Saga Tecnologia" className="logo" />
                <NavBar />
            </div>
            <section className="container" style={{margin: '40px auto'}}>
                <SubTitle text={i18n.t('page.products.itens.wmsInv.title').toString()} />
                <div className="row" style={{marginTop: '20px'}}>
                    <div className="col-md-7 col-sm-12">
                        <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>
                        {i18n.t('page.products.itens.wmsInv.sub').toString()}
                        </p>
                        <GoToAction />
                    </div>    
                    <div className="col-md-5 col-sm-12">
                        <img src={img} alt="SAGA INV" width="100%" />
                    </div>
                </div> 
            </section>
            <RelatedServices />
            <Footer />
        </>
    )
}
export default wmsInv;