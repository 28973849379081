import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import TopBar from "../../components/TopBar";
import logo from "../../images/logo.png";

const internal = () => {
  return (
    <>
      <TopBar />
      <div className="container">
        <img src={logo} alt="Logo Saga Tecnologia" className="logo" />
        <NavBar />
      </div>
      <div></div>
      <div style={{ textAlign: "left", margin: "5rem 0 1rem 0" }}>
        <a
          href="https://outlook.office.com/mail/?ui=pt-BR&rs=BR&auth=1"
          target="_blank"
          className="btn btn-lg"
          style={{
            margin: "0 auto",
            background: "#752C2D",
            color: "#FFF",
            display: "flex",
            width: "220px",
            justifyContent: "center",
            alignItems: "center",
          }}
          rel="noreferrer"
        >
          E-mail Interno{" "}
        </a>
      </div>
      <div style={{ textAlign: "left", margin: "1rem 0 5rem 0" }}>
        <a
          href="https://register.sagatecnologia.com.br"
          target="_blank"
          className="btn btn-lg"
          style={{
            margin: "0 auto",
            background: "#752C2D",
            color: "#FFF",
            display: "flex",
            width: "220px",
            justifyContent: "center",
            alignItems: "center",
          }}
          rel="noreferrer"
        >
          Controle de ponto
        </a>
      </div>

      <Footer />
    </>
  );
};
export default internal;
