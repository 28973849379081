const messages = {
    pt: {
        translations: {
            page: {
                home: {
                    slogan: 'Logística Inteligente',
                    alguns:"ALGUNS DE NOSSOS CLIENTES",
                    listProducts: {
                        subTitle: 'O que Fazemos',
                        advanced: {
                            title: 'SAGA WMS ADVANCED',
                            text: "Sistema de gerenciamento logístico automatizado para: Indústrias, CD's e Operadores Logísticos."
                        },
                        light: {
                            title: 'SAGA WMS LIGHT',
                            text: "É uma versão do WMS com os processos logísticos de Recebimento, Expedição, Inventário, Ressuprimento e Transferência."
                        },
                        cloud: {
                            title: 'SAGA WMS CLOUD',
                            text: "Com o SAGA CLOUD você tem todas as suas operações SAGA na nuvem. Administre de onde estiver com segurança."
                        },
                        wcs: {
                            title: 'SAGA WCS',
                            text: "Sistema de controle e acompanhamento dos processos do armazém e gerenciamento da operação."
                        },
                        voice: {
                            title: 'PICKING BY VOICE',
                            text: "É um módulo que integra o SAGA WMS com equipamentos de voz otimizando o processo de picking."
                        },
                        service: {
                            title: 'SAGA SERVICE',
                            text: "É uma ferramenta de Facilities Management desenvolvida para auxiliar e resolver os mais diversos processos de suas instalações."
                        },
                        trabalho:{
                            trabalhe:"Trabalhe Conosco",
                            nome:"Nome",
                            curriculo:"Currículo",
                            adm:"Administrativo",
                            comercial:"Comercial",
                            dev:"Desenvolvimento",
                            projetos:"Projetos",
                            sup:"Suporte",
                            apresentacao:"Apresentação",
                            arquivo:"Nenhum arquivo escolhido",
                        },
                           
                    },
                    sectionWms:{
                        title: 'SAGA WMS PARA SUA EMPRESA',
                        listItem:{
                            convoca: {
                                text: "Convocação Ativa"
                            },
                            rastreabilidade: {
                                text: "Rastreabilidade"
                            },
                            produtividade: {
                                text: "Produtividade"
                            },
                            controle: {
                                text: "Controle da Operação"
                            },
                            reducao: {
                                text: "Redução de Custos"
                            }
    
                        },
                        btnGoToAction: 'SAIBA MAIS',
                    },
                    client:{
                        title: 'PALAVRAS DOS NOSSOS CLIENTES',
                        listItem:{
                            granfino: {
                                text: "Ao longo dos anos me tornei admirador do processo do WMS e endosso as palavras da nossa Sra. Presidente. Mais uma vez venho externar minha grande satisfação em relação aos resultados atingidos e empenho dos envolvidos no planejamento, desenvolvimento e execução. Conto ainda com atenção e dedicação das áreas para que as pequenas pendências sejam resolvidas, o quanto antes, para termos uma operação mais redonda possível. Demos um salto de performance de décadas e profissionalismo na área logística, tenham ciência disso! Que esse case de sucesso seja responsável por outras inúmeras tamanhas mudanças em nossa Empresa. Obrigado a todos.",
                                subTi: "Felipe Lantimant - Granfino"
                            },
                            smart: {
                                text: "Trabalhamos com o SAGA desde o início de nossas atividades. E o escolhemos por ser o único que se adequou precisamente às nossas necessidades. Trata-se de um sistema de fácil utilização e com um visual muito prático, oferecendo muitas opções de configuração.",
                                subTi: "José Gorgulho - SMART Logística"
                            },
                            cem: {
                                text: "Após uma intensa avaliação optamos pelo sistema SAGA WMS por apresentar muito de nossos processos já customizados. Isso agilizou em muito nossa implantação. Nossa produtividade aumentou em 30% com redução nos custos fixos. Somos muito bem atendidos por uma equipe treinada e especializada quando precisamos acionar o suporte técnico. O sistema tem se adaptado a todos os nossos problemas sazonais do ano. Estamos muito contentes e confiantes com este sistema WMS.",
                                subTi: "Renê Dalla Vecchia - Lojas CEM"
                            },
                            ype: {
                                text: "A decisão pelo SAGA WMS foi tomada com base em seus diferenciais: Produto com maiores funcionalidades, aderentes à nossa operação, com ênfase na convocação ativa, na qual acreditamos que será um determinante fator de aumento de produtividade, Número de implantações com sucesso em várias empresas, sobretudo indústrias de bem de consumo, como é nosso caso, Experiência em integração com sistema de transelevador, Qualidade e presteza no atendimento.",
                                subTi: "Marcos Tourinho - Ypê"
                            },
                            
                        }
                    },
                    suport:{
                        listItem:{
                            tempo: {
                                text: "31 anos",
                                subTi: "Vivência Logística"
                            },
                            sup: {
                                text: "24 horas",
                                subTi: "Suporte"
                            },
                            projetos: {
                                text: "+100",
                                subTi: "Projetos Concluídos"
                            },
                            parceiros: {
                                text: "+20",
                                subTi: "Parceiros"
                            }
                        }
                    },
                    certificate:{
                        title: 'Certificações',
                        listItem:{
                            first: {
                                text: "Microsoft Certified Partner"
                            },
                            second: {
                                text: "Microsoft.net"
                            },
                            third: {
                                text: "Oracle"
                            },
                            fourth: {
                                text: "Motorola PartnerSelect ISV partner"
                            },
                            fifth: {
                                text: "C#"
                            }
                        }
                    },
                    award:{
                        title: 'Premiações',
                        listItem:{
                            first: {
                                text: "Premiação Revista CRN Brasil"
                            },
                            second: {
                                text: "Troféu Canais de Referência Melhor Atuação em Industria 2008"
                            },
                            third: {
                                text: "Microsoft"
                            },
                            fourth: {
                                text: "Projeto SAGA Advacend eleito vencedor das categorias Smart Client, Windows Server 2003"
                            },
                            fifth: {
                                text: "Connected Applications, Visual Studio . NET 2005."
                            }
                        }
                    },
                    

                    
                    
                },
                about:{
                    sobreNos:{
                        title: 'SOBRE NÓS',
                        listItem:{
                            sobre: {
                                text: "Nós somos uma empresa de softwares há 31 anos atuando no mercado. Voltada para soluções de gestão, gerenciamento de armazéns e gestão de instalações (Facilities Management). Com um produto 100% brasileiro, equipe especialista e multidisciplinar - a SAGA atende empresas e indústrias de todos os seguimentos de distribuição, logística e manufatura, desde a etapa inicial de especificação, até a implantação do sistema WMS e posterior suporte operacional de seus sistemas. Tornando nossos clientes mais competitivos no mercado em que atuam. Nosso objetivo é ofertar a melhor solução especialista de gestão logística e instalações, utilizando tecnologia de ponta com o diferencial em ter o maior número de recursos nativos, garantindo a plena satisfação dos clientes.",
                                sub: "NOSSA HISTÓRIA"
                            }
                        }
                    },
                    list:{
                        listItem:{
                            orig: {
                                text: "Origem",
                                sub: "Fundada em 1992 com o foco em automação industrial, a S&A Sistemas (hoje SAGA Tecnologia) cresceu desenvolvendo soluções complexas para seus clientes dos segmentos de siderurgia e mineração. Com clientes como Cemig, Samarco, Mannesmann, Açominas e Magnesita.",
                                subText: 'Fundada Por: Guilherme Salles e Bartholomeu Amaral'
                            },
                            dema: {
                                text: "Mudança de Demandas",
                                sub: "Em 1995 uma nova fase se iniciou, com mudanças no mercado de automação e as novas demandas de clientes com processos mais intensos de armazenagem e expedição."
                            },
                            estr: {
                                text: "Mudança de Estrutura",
                                sub: "Sua equipe viu-se em posição de desenvolver software para processos logísticos com grande aplicação de códigos de barras e tecnologia de radiofrequência, quando aplicamos toda a tecnologia adquirida na fase de automação industrial."
                            },
                            atua: {
                                text: "Atualidade",
                                sub: "O nosso foco principal de atuação no mercado é a excelência em soluções para gestão logística. Promovemos o desenvolvimento, a implantação, o treinamento, o suporte pós-implantação e toda comercialização de seus produtos e serviços. Atuamos na área de automação de processos logísticos, tendo agregado à nossa linha tecnológica de produtos e serviços para esta área, todo seu “know-how” e experiência acumulada na automação de processos industriais. Com uma equipe especializada e multidisciplinar, profissionais formados em diversas áreas, a SAGA proporciona aos seus clientes uma evolução linear da solução, desde a etapa inicial de consultoria até a implantação e posterior suporte operacional do produto. "
                            }

                        }
                    },
                    btn:{
                        listItem:{
                            btnContato: {
                                text: "VENHA FAZER PARTE DESSA HISTÓRIA",
                                sub: "CONTATO"
                            },
                        }
                    },
                    servicos:{
                        log:"Logística e automação",
                        logsub:"Módulos adicionais específicos agregam informação e controles aos sistemas SAGA WMS, incluindo: Lastro Fiscal, Central de Custos, Central de Documentação/Contratos, Módulo de Portaria, Módulo PMP Produtividade, Módulo de Abastecimento, dentre outros.",
                        eqp:"Equipe especializada",
                        eqpsub:"A Saga Tecnologia possui uma equipe especializada em programação e logística trabalhando sempre em melhorias do SAGA WMS",
                        sup:"Suporte",
                        supsub:"A Saga Tecnologia possui uma grande equipe de suporte atentendo seus clientes sempre que necessário para dar continuidade aos processos de cada empresa.",
                        mis:"Misão",
                        missub:"Criar, entregar e manter soluções de SCM SAGA com qualidade e assertividade, criando valor para clientes, acionistas e colaboradores.",
                        vis:"Visão",
                        vissub:"Oferecer produtos com tecnologia, usabilidade e inovação. Vender novos serviços, parcerias, pontualidade na entrega, padronização e gerar rentabilidade satisfazendo nossos clientes.",
                        val:"Valores",
                        valsub:"Promover o crescimento sustentado da empresa, reagir rapidamente aos novos desafios do mercado, garantir dianteira tecnológica e conceitual em relação aos concorrentes, agregar valor para acionistas, clientes e colaboradores, desenvolver a cultura da excelência, proporcionar ambiente de trabalho saudável e qualidade de vida aos colaboradores."
                    }    

                },
                contact:{
                  info:"Informações" , 
                  title: 'CONTATO',
                  btn:"Solicitar Visita",
                  alert:"E-mail enviado com sucesso",
                  listItem:{
                      textoInicial: {
                          text: "Fale Conosco",
                          subTi: "Deixe sua mensagem abaixo em caso de dúvidas e interesse pelos nossos serviços, estaremos prontos para lhe responder o mais rápido possível"
                      },
                      info:{
                          name:"Nome",
                          emai:"E-mail",
                          tele:"Telefone",
                          empr:"Empresa",
                          office: "Cargo",
                          cida:"Cidade",
                          esta:"Estado",
                          mens:"Mensagem",
                          btne:"Enviar"

                      }
                      
                  }
                },
                products:{
                  itens:{
                    btn:"Solicitar Visita",
                    wmsAdvanced:{
                         title:"WMS - SAGA ADVANCED",
                         sub:"O SAGA WMS é um sistema especialista de gerenciamento logístico automatizado para: Indústrias, CD's e Operadores Logísticos. Voltado à necessidade do cliente, o SAGA WMS opera com uma topologia básica de servidores locais ou em nuvem, com computador como host interligando os servidores aos coletores de dados via Radiofrequência e/ou RFID. Com total foco no ritmo, produtividade, acuracidade, segurança e dinamismo operacional, suporta processos de adequação às necessidades reais de operação, sendo capaz de executar operações como: controle de portaria, recebimento, armazenamento, inventários, controle de lotes de fabricação, expedição, otimização de armazenagem e tem como outros destaques:",
                         li1:"Agilizar e otimizar o fluxo de materiais para expedições;",
                         li2:"Reduzir o ciclo de armazenagem, custo e operação;",
                         li3:"Eliminar o fluxo oral de informações reduzindo falhas, perdas ou retrabalhos;",
                         li4:"Melhorar o controle dos processos e utilização dos recursos;",
                         li5:"Identificar QUEM fez O QUÊ e QUANDO (3Ws);",
                         li6:"Controlar prazo de validade de mercadorias, lotes, estoque em tempo real;",
                         li7:"Criar regras de negócios por clientes (regras de cortes, FEFO, FIFO e etc.);",
                         li8:"Reduzir investimentos em estoques e infraestrutura",
                         Sub:"PRINCIPAIS CARACTERISTICAS",
                         subti:"O SAGA Advanced é um sistema completo de gerenciamento logístico para indústrias, operadores logísticos, centrais de distribuição e armazenagem, atacado/distribuidores e varejo.",
                         convocacaoAtiva:  {
                            title: 'CONVOCAÇÃO ATIVA',
                            text:  'O sistema direciona as tarefas aos funcionários de forma contínua, respeitando regras de priorização e desempenho.Os serviços buscam os operadores e não o inverso, imprimindo seu ritmo à operação. A Convocação Ativa evita ociosidade, aumentando a produtividade no SAGA WMS sendo esse um grande diferencial operacional e desempenho superior à operação.'
                         },
                         reducaoCusto:  {
                            title: 'REDUÇÃO DE CUSTOS',
                            text:  'Com o SAGA você consegue equacionar os diversos recursos da operação (mão-de-obra, dispositivos móveis, regiões de armazenagem) maximizando seus resultados através do uso da convocação ativa, arquitetura centralizada e modo gráfico. O SAGA trabalha para economizar sua logística para o futuro da sua empresa'
                         },
                         rastreabilidade:  {
                            title: 'RASTREABILIDADE FÍSICA',
                            text:  'O SAGA WMS permite rastreabilidade total (física) de cada unidade movimentada, podendo controlar estoques por lote de fabricação, datas de validade, números de série, dentre várias outras características. Promove total rastreabilidade de mercadorias e serviços, ordens e documentos.'
                         },
                         mapeamento:  {
                            title: 'MAPEAMENTO DOS RECURSOS',
                            text:  'O rastreamento dos recursos permite ao SAGA WMS otimizar a operação promovendo redução nas movimentações e controle da produtividade.'
                         },
                         flexibilidade:  {
                            title: 'FLEXIBILIDADE E SEGURANÇA',
                            text:  'Opção entre operação em papel, rádio frequência ou forma mista.'
                         },
                         Monitoria:  {
                            title: 'MONITORIA E PRODUTIVIDADE',
                            text:  'É uma ferramenta essencial na análise e comparação das equipes, indivíduos e Depósitos, fornecendo um conjunto completo de indicadores (KPIs) de desempenho, proporcionando métricas utilizadas em diversas metodologias de remuneração variável (avaliação por desempenho).'
                         }

                    },
                    wcs:{
                        title:"SAGA WMS",
                        sub:"O SAGA WCS (Warehouse Control System) é uma ferramenta online responsiva conectada com SAGA WMS, importante para o controle, acompanhamento e tomadas de decisões nos processos logísticos de sua empresa. Gerencie com SAGA WCS sua operação remotamente e tenha sempre em mãos os dados de recebimento, expedição, ocupação, produtividade, metas, entre outras informações da operação.",
                        subtitle:"PRINCIPAIS FUNCIONALIDADES",
                        controle:  {
                            title: 'CONTROLE DA OPERAÇÃO',
                            text:  'Monitoria a produtividade do processo de expedição de acordo com os serviços, pedidos, peças, UMAs e outras. O resumo da operação como pedidos não gerados, produção diária e serviços em execução. '
                         },
                         ranking:  {
                            title: 'RANKING DE OPERADORES',
                            text:  "Análise de produtividade por atividade sendo mensal, diária ou em tempo real por operador. É possível analisar por serviços, peças, pedidos, peso, volume e outras. Pode ser exibido em TV's ou monitores na própria operação"
                         },
                         mapa:  {
                            title: 'MAPA DE DEPÓSITO',
                            text:  "Através de uma visão dinâmica é possível ter os valores de ocupação do armazém, espaço físico dos endereços, ocupação por produtos/grupos/regiões e até de fornecedores. Permite visualizar estoques bloqueados ou próximos de vencimento, segregados por bloqueios, endereços com serviços, inventários e muito mais."
                         }
                        
                    },
                    wcsLight:{
                        title:"WMS - SAGA LIGHT",
                        sub:"O SAGA LIGHT é uma versão do WMS com os processos logísticos de Recebimento, Expedição, Inventário, Ressuprimento e Transferência.",
                        subtitle:"PRINCIPAIS CARACTERÍSTICAS",
                        subti: "O NOVO SAGA LIGHT é um sistema voltado para empresas que precisam de um WMS com licença e implantação de baixo custo, sendo vertical e especialista.",
                        rapida:  {
                            title: "RÁPIDA IMPLANTAÇÃO",
                            text:  "O sistema possibilita uma implantação mais enxuta, sendo mais rápida e objetiva."
                         },
                         reducao:  {
                            title: "REDUÇÃO DE CUSTOS",
                            text:  "Com o SAGA você consegue equacionar os diversos recursos da operação (mão-de-obra, dispositivos móveis, regiões de armazenagem) maximizando seus resultados através do uso da convocação ativa, arquitetura centralizada e modo gráfico. O SAGA trabalha para economizar sua logística para o futuro da sua empresa."
                         },
                         convocacao:  {
                            title: "CONVOCAÇÃO ATIVA",
                            text:  "O sistema direciona as tarefas aos funcionários de forma contínua, respeitando regras de priorização e desempenho. Os serviços buscam os operadores e não o inverso, imprimindo seu ritmo à operação. A Convocação Ativa evita ociosidade, aumentando a produtividade no SAGA WMS sendo esse um grande diferencial operacional e desempenho superior à operação."
                         },
                         custo:  {
                            title: "CUSTO X BENEFÍCIO",
                            text:  "WMS especialista otimizado com as principais funcionalidades do Advanced. Atende perfeitamente os processos de recebimento, expedição, ressuprimento e inventário."
                         },
                         monitoria:  {
                            title: "MONITORIA E PRODUTIVIDADE",
                            text:  "É uma ferramenta essencial na análise e comparação das equipes, indivíduos e Depósitos, fornecendo um conjunto completo de indicadores (KPIs) de desempenho, proporcionando métricas utilizadas em diversas metodologias de remuneração variável (avaliação por desempenho)."
                         }
                    },
                    wmsCloud:{
                        title:"WMS - SAGA CLOUD",
                        sub:"Com o SAGA CLOUD você ja pode ter todas as suas operações SAGA na nuvem. Administre de onde estiver com segurança e tenha as funcionalidades garantidas e controle completo do seu armazém. Otimize o tempo de implantação e reduza a necessidade de investimento em novos servidores.",
                        subtitle:"PRINCIPAIS CARACTERÍSTICAS",
                        subti: "O NOVO SAGA Cloud permite que o cliente adquira um WMS especialista e vertical em modelo de serviço diminuindo custos.",
                        menor:  {
                            title: "MENOR CUSTO EM INFRAESTRUTURA",
                            text:  "O usuário não precisa se preocupar com sistema operacional, hardware, servidores e rede que está usando em seu computador pessoal, podendo acessar seus dados na “nuvem computacional”, independentemente disso."
                         },
                         convocacao:  {
                            title: "CONVOCAÇÃO ATIVA",
                            text:  "O sistema direciona as tarefas aos funcionários de forma contínua, respeitando regras de priorização e desempenho. Os serviços buscam os operadores e não o inverso, imprimindo seu ritmo à operação. A Convocação Ativa evita ociosidade, aumentando a produtividade no SAGA WMS sendo esse um grande diferencial operacional e desempenho superior à operação."
                         },
                         mapeamento:  {
                            title: "MAPEAMENTO DOS RECURSOS",
                            text:  "O rastreamento dos recursos permite ao SAGA WMS otimizar a operação promovendo redução nas movimentações e controle da produtividade."
                         },
                         monitoria:  {
                            title: "MONITORIA E PRODUTIVIDADE",
                            text:  "É uma ferramenta essencial na análise e comparação das equipes, indivíduos e Depósitos, fornecendo um conjunto completo de indicadores (KPIs) de desempenho, proporcionan¬do métricas utilizadas em diversas metodologias de remuneração variável (avaliação por desempenho)."
                         }
                    },
                    wmsInv:{
                        title:"SAGA INV ",
                        sub:"O Saga INV é uma ferramenta que foi criada afim de dar praticidade nas operações de inventário sem a necessidade de controlar o estoque armazenado.Iniciando as contagens a partir de uma planilha ou de um envio de dados via interface web. O INV também funciona de forma totalmente autônoma, onde com poucos cliques é possível iniciar as contagens e enviar para os operadores.Sem a necessidade de utilização de equipamentos sofisticados, o INV foi projetado para funcionar em aparelhos com o sistema Android e de fácil acesso, como celulares e tablets.",
                        btn:"ÁREA DO CLIENTE"
                    }
                    
                      
                  }

                },
                partners:{
                    title:"PARCEIROS",
                    Subtitle:"PARCEIROS - SAGA TECNOLOGIA",
                    btn:"SEJA PARCEIRO",
                    btnhomo:"COLETORES HOMOLOGADOS",
                },
                termo:{
                    part1:"Ao utilizar este site você concorda com os",
                    part2:"termos de uso e política de privacidade",
                    part3:"da SAGA TECNOLOGIA.",
                    concordo:"CONCORDO",
                },
                ouvidoria:
                {
                    title:"Ouvidoria",
                    name:"Nome",
                    contato:"Contato",
                    mensagem:"Mensagem"
                }    

                
            },
            components: {
                navBar: {
                    home: 'INÍCIO',
                    about: 'SOBRE',
                    contacts: 'CONTATO',
                    produtos: "PRODUTOS",
                    suporte: "SUPORTE"
                },
                footer: {
                    trabalhe:"TRABALHE CONOSCO", 
                    sobre:{
                        title: 'SOBRE A SAGA',
                        listItem:{
                            text: {
                                text: "Para atender a grande diversidade de segmentos empresariais envolvidos com as questões logísticas e de gestão, nossos produtos buscam a maior versatilidade possível, considerando a diversidade de processos existentes."
                            }
                        }
                    },
                    contato:{
                        title: 'CONTATO',
                        listItem:{
                            ende: {
                                text: "Endereço:",
                                sub: "Av. Raja Gabáglia, 3117 - Sl. 224 Bairro São Bento - Belo Horizonte / MG - Cep: 30350-563"
                            },
                            email: {
                                text: "Email:",
                                sub: "sacom@sasistemas.com.br"
                            },
                            tele: {
                                text: "Telefone:",
                                sub: "+55 31 4501-0000"
                            },
                            skyp: {
                                text: "Skype:",
                                sub: "carol.salles06"
                            },
                            what: {
                                text: "WhatsApp:",
                                sub: "+55 31 99315-3709"
                            }
                        }
                    },
                    saibaMais:{
                        title: 'SAIBA MAIS',
                        listItem:{
                            saiba: {
                                text: "Quer nos conhecer melhor cadastre seu email aqui:"
                            }
                        }
                    },

                },
                RelatedServices:{
                    title:"SERVIÇOS RELACIONADOS",
                    supo:{
                        title:"SUPORTE TÉCNICO",
                        text:"A Saga Tecnologia possui uma grande equipe de suporte atentendo seus clientes sempre que necessário para dar continuidade aos processos de cada empresa."
                    },
                    asse:{
                        title:"ASSESSORIA TÉCNICA",
                        text:"Podemos oferecer alterações do software visando ajuste e atendimento específico para alguma funcionalidade de acordo com a necessidade do cliente."
                    },
                    trei:{
                        title:"TREINAMENTOS",
                        text:"Oferecemos treinamentos de uso do sistema de acordo com a necessidade de cada empresa."
                    },
                    impl:{
                        title:"IMPLANTAÇÃO & CONSULTORIA",
                        text:"Profissionais capacitados para executar implantação do seu software com know-how para auxiliar nas definições voltadas para sua empresa."
                    },

                },
                topbar:{
                    Acessibilidade: "Acessibilidade:"
                },
                Suporte:{
                    text:"Nossa equipe de suporte e manutenção a clientes tem como prioridade a máxima qualidade no atendimento, prezamos pelo alto desempenho operacional de nossos produtos. Com analistas especializados para cada cliente contamos com um time com alto nível de conhecimento técnico para todos os produtos da linha Saga Tecnologia. O diferencial entre as opções de suporte se resume simplesmente no horário de atendimento de cada uma das opções, sendo que no suporte Ouro fornecemos atendimento 24 horas por dia nos sete dias da semana de forma ininterrupta. O suporte Prata, fornecemos atendimento em horário personalizado, de acordo com o horário operacional do cliente e o suporte Bronze o atendimento é feito em horário comercial. Para qualquer problema operacional ou dúvida entre em contato com suporte Saga. Temos um profissional pronto para atendê-lo de forma rápida e segura para que não cause interrupção em sua operação logística.",
                    title:"MODALIDADE",
                    bronze:"BRONZE",
                    prata:"PRATA",
                    ouro:"OURO",
                    tr1:"SUPORTE HORÁRIO COMERCIAL",
                    tr2:"SUPORTE HORÁRIO PERSONALIZADO",
                    tr3:"SUPORTE ININTERRUPTO (24x7)",
                    tr4:"ANALISTA ESPECÍFICO POR CLIENTE COM ANALISTA BACKUP",
                    tr5:"GERENTE DE CONTA",
                    tr6:"ADERÊNCIA A CUSTOMIZAÇÔES INDIVIDUAIS",
                    tr7:"EVOLUÇÃO CONTÍNUA ILIMITADA",
                    tr8:"FRANQUIA DE SUPORTE OPERACIONAL MEDIANTE CONTRATO PRÓPRIO",
                    tr9:"FRANQUIA ILIMITADA PARA CHAMADOS DE FALHAS",
                }
            }
        }
    }
}

export { messages }