const messages = {
    en: {
        translations: {
            page: {
                home: {
                    slogan: 'Smart Logistics',
                    alguns:"SOME OF OUR CLIENTS",
                    listProducts: {
                        subTitle: 'What we do',
                        advanced: {
                            title: 'SAGA WMS ADVANCED',
                            text: "Warehouse management system for: industries, warehouses, logistical operators."
                        },
                        light: {
                            title: 'SAGA WMS LIGHT',
                            text: "Is a version of WMS with the logistical process of, receipt, shipment, stocktaking, resupply and transfer."
                        },
                        cloud: {
                            title: 'SAGA WMS CLOUD',
                            text: "With the saga Cloud you have all the saga operations in cloud. Safely manage from wherever you are."
                        },
                        wcs: {
                            title: 'SAGA WCS',
                            text: "Control and following system of warehouse process and management of operation."
                        },
                        voice: {
                            title: 'PICKING BY VOICE',
                            text: "Is module that integrates saga wms with coice equipment, optimizing the picking process."
                        },
                        service: {
                            title: 'SAGA SERVICE',
                            text: "Is a facilities management tool developed to assist and solve the most diverse processes of your company."
                        },
                        trabalho:{
                            curriculo:"Resume",
                            trabalhe:"WORK WITH US",
                            nome:"Name",
                            adm:"Administrative",
                            comercial:"Commercial",
                            dev:"Development",
                            projetos:"Projects",
                            sup:"Support",
                            apresentacao:"presentation",
                            arquivo:"",
                        },
                            
                        
                    },
                    sectionWms:{
                            title: 'Saga wms for you',
                            listItem:{
                                convoca: {
                                    text: "Activity convocation"
                                },
                                rastreabilidade: {
                                    text: "traceability"
                                },
                                produtividade: {
                                    text: "productivity"
                                },
                                controle: {
                                    text: "Operation Control"
                                },
                                reducao: {
                                    text: "Cost Reduction"
                                }
                            },
                            btnGoToAction: 'READ MORE',

                    },
                    client:{
                        title: 'Words from our customers',
                        listItem:{
                            granfino: {
                                text: "Over the years I have become admirer of the WMS process and I endorse the words of our Ms. President. Once again I would like to express my great satisfaction with the results achieved and the commitment of those involved in planning, development and execution I also count on the attention and dedication of the areas so that the small pending issues are resolved, as soon as possible, so that we have the most rounded operation possible. We made a leap of decades of performance and professionalism in the logistics area, be aware of that! May this successful case be responsible for countless other such changes in your company, Thank you all.",
                                subTi: "Felipe Lantimant - Granfino"
                            },
                            smart: {
                                text: "We have been working with Saga since the beginning of our activities. And we choose it because it was the only one that precisely suited our needs. It is an easy-to-use system with a very practical look, offering many configuration options. ",
                                subTi: "José Gorgulho - SMART Logística"
                            },
                            cem: {
                                text: "After an intense evaluation we choose by Saga WMS for showing us many of our already customized process. This greatly speeded up our deployment. Our productivity increased by 30% with a reduction in fixed costs. We are very well attended by a trained and specialized team when we need to call technical support. The system has adapted to all our seasonal issues of the year. We are very happy and confident with this WMS system.",
                                subTi: "Renê Dalla Vecchia - Lojas CEM"
                            },
                            ype: {
                                text: "The decision for the Saga WMS was taken based on this differentials: product with greater functionality, adhering to our operation, with emphasis on active convocation, in which we believe it will be a determining factor in increasing productivity, number of successful implementations in several companies, especially consumer goods industries, as is our case. Experience in integration with a stacker crane system, quality and prompt help desk.",
                                subTi: "Marcos Tourinho - Ypê"
                            },
                            
                            
                        }
                    },
                    suport:{
                        listItem:{
                            tempo: {
                                text: "31 years",
                                subTi: "Logistical experience "
                            },
                            sup: {
                                text: "24 hours",
                                subTi: "help desk"
                            },
                            projetos: {
                                text: "+100",
                                subTi: "finished projects "
                            },
                            parceiros: {
                                text: "+20",
                                subTi: "partner"
                            }
                        }
                    },
                    certificate:{
                        title: 'Certifications',
                        listItem:{
                            first: {
                                text: "Microsoft Certified Partner"
                            },
                            second: {
                                text: "Microsoft.net"
                            },
                            third: {
                                text: "Oracle"
                            },
                            fourth: {
                                text: "Motorola PartnerSelect ISV partner"
                            },
                            fifth: {
                                text: "C#"
                            }
                        }
                    },
                    award:{
                        title: 'Awards',
                        listItem:{
                            first: {
                                text: "Magazine award CRN Brasil."
                            },
                            second: {
                                text: "Reference channels trophy best performance in industry."
                            },
                            third: {
                                text: "Microsoft."
                            },
                            fourth: {
                                text: "Saga Advanced project winner of the Smart client, windows categories."
                            },
                            fifth: {
                                text: "Server 2003 connected applications, Visual Studio .NET 2005."
                            }
                        }
                    }
                    
                    
                },
                about:{
                    sobreNos:{
                        title: 'ABOUT US',
                        listItem:{
                            sobre: {
                                text: " Saga is a software company operating in the market for 31 years. Focused on management solutions, warehouse management and facilities management. With a 100% Brazilian product, a specialist and multidisciplinary team – Saga serves companies and industries from all segments of distribution, logistics and manufacturing from the initial specification stage, to implementation of the WMS system and subsequent operation support of their systems. Making in which they operate. Our objective is to offer the best specialist solution for logistics and facilities management, Using state-of-the-art technology with the differential of having the largest number of native resources, ensuring full customer satisfaction. ",
                                sub: "Our story"
                            }
                        }
                    },
                    list:{
                        listItem:{
                            orig: {
                                text: "Creation and foundation",
                                sub: "Founded in 1992 with a focus on industrial automation, S&A sistemas (today Saga tecnologia) grew by developing complex solutions for the customers in the steel and mining segments. With clients such as Cemig, Samarco, Mannesmann, Açominas and Magnesita",
                                subText: 'Founded by: Guilherme Salles e Bartholomeu Amaral'
                            },
                            dema: {
                                text: "Changing demands",
                                sub: "In 1995 a new phase was beginning, with de automation market changes and a new demands of customers with process more intense of storage and dispatch. "
                            },
                            estr: {
                                text: "Structure change",
                                sub: "His team found himself in a position to develop a software for logistical processes with extensive application of bar codes and radiofrequency technology, when we applied all the technology acquired in the industrial automation phase."
                            },
                            atua: {
                                text: "Saga Tecnologia",
                                sub: "Our main focus in the market is excellence in logistics management solutions. Saga Tecnologia promotes the development, implementations, training, pont-implementation help desk and all marketing of its products and services. We operate in the area of automation of logistics processes having added to our technological line of products and services for this area, all its know-how and accumulated experience in the automation of industrial processes. With a specialized and multidisciplinary team, professionals trained in several areas, Saga provides its customers with a linear evolution of the solution, from the initial consulting stage to the implementation and subsequent help desk of the product."
                            }

                        }
                    },
                    btn:{
                        listItem:{
                            btnContato: {
                                text: "COME BE PART OF THIS STORY",
                                sub: "CONTACT"
                            },
                        }
                    },
                    servicos:{
                        log:"Logistics and automation",
                        logsub:"Specific additional modules add information and controls to saga WMS systems, including Fiscal ballast, cost center, documentation contracts center, ordinance module, PMP productivity module, supply module, among others.",
                        eqp:"Specialized team",
                        eqpsub:"Saga Tecnologia has a team specialized in programming and logistics, always working on improvements to Saga WMS",
                        sup:"Help desk",
                        supsub:"Saga Tecnologia has a great help desk team attending to our customers whenever necessary to give continuity to the processes of each company",
                        mis:"Mission",
                        missuv:"Create, deliver and maintain SCM SAGA solutions with quality and assertiveness, creating value for customers, shareholders and employees",
                        vis:"Vision",
                        vissub:"offer products with technology, usability and innovation. sell new services, partnerships, punctual delivery, standardization and generate profitability satisfying our customers",
                        val:"Values",
                        valsub:"promote the company's sustained growth, react quickly to new market challenges, ensure a technological and conceptual advantage over competitors, add value to shareholders, customers and employees, develop a culture of excellence, provide a healthy work environment and quality of life for employees"
                    }        
                },
                contact:{
                 info:"Information" ,   
                 title: 'CONTACT',
                 btn:"Request Visit",
                 alert:"Email sent with success",
                 listItem:{
                     textoInicial: {
                         text: "CONTACT US",
                         subTi: "Leave your message below if you have any questions or interest in our services, we will be ready to answer you as soon as possible."
                     },
                     info:{
                         name:"Name",
                         emai:"E-mail",
                         tele:"Number Phone",
                         empr:"Name Company",
                         office: "Office",
                         cida:"City",
                         esta:"State",
                         mens:"Message",
                         btne:"SEND"

                     }

                     
                     
                 }
                  
                },
                products:{
                    itens:{
                        btn:"Request Visit",
                      wmsAdvanced:{
                           title:"SAGA WMS ADVANCED ",
                           sub:"SAGA WMS is a specialist automated logistics management system for: Industries, warehouses and Logistics Operators. Aimed at the customer's needs, SAGA WMS operates with a basic topology of local or cloud servers, with a computer as a host connecting the servers to the data collectors via Radiofrequency and/or RFID. With total focus on rhythm, productivity, accuracy, safety and operational dynamism, it supports processes to adapt to the real needs of the operation, being able to perform operations such as: lobby control, receipt, storage, inventories, control of manufacturing batches, shipping, storage optimization and has as other highlights:",
                           li1:"Streamline and optimize the flow of materials for expeditions;",
                           li2:"Reduce the storage, cost and operation cycle;",
                           li3:"Eliminate the oral flow of information, reducing failures, losses or reworks;",
                           li4:"Improve the control of processes and use of resources;",
                           li5:"Identify WHO did WHAT and WHEN (3Ws);",
                           li6:"Control shelf life of goods, batches, stock in real time;",
                           li7:"Create business rules by customers (cuts rules, FEFO, FIFO and etc.);",
                           li8:"Reduce investments in inventories and infrastructure;",
                           Sub:"MAIN FEATURES",
                           subti:"SAGA Advanced is a complete logistics management system for industries, logistics operators, distribution and storage centers, wholesale/distributors and retail.",
                           convocacaoAtiva:  {
                              title: 'ACTIVE CONVOCATION',
                              text:  'The system directs tasks to employees on an ongoing basis, respecting prioritization and performance rules. The services look for the operators and not the other way around, setting their rhythm to the operation. The Active Convocation avoids idleness, increasing productivity in the SAGA WMS, which is a great operational differential and superior performance to the operation.'
                           },
                           reducaoCusto:  {
                              title: 'COST REDUCTION',
                              text:  'With SAGA you can equate the various resources of the operation (labor, mobile devices, storage regions) maximizing your results through the use of active convocation, centralized architecture and graphical mode. SAGA works to save your logistics for the future of your company'
                           },
                           rastreabilidade:  {
                              title: 'PHYSICAL TRACEABILITY',
                              text:  'SAGA WMS allows total (physical) traceability of each unit handled, being able to control stocks by manufacturing batch, expiration dates, serial numbers, among many other characteristics. It promotes full traceability of goods and services, orders and documents.'
                           },
                           mapeamento:  {
                              title: 'RESOURCE MAPPING',
                              text:  'Resource tracking allows SAGA WMS to optimize the operation, promoting a reduction in movements and productivity control.'
                           },
                           flexibilidade:  {
                              title: 'FLEXIBILITY AND SECURITY',
                              text:  'Choice between paper operation, radio frequency or mixed form.'
                           },
                           Monitoria:  {
                              title: 'MONITORING AND PRODUCTIVITY',
                              text:  'It is an essential tool in the analysis and comparison of teams, individuals and warehouses, providing a complete set of performance indicators (KPIs), providing metrics used in various variable compensation methodologies (performance evaluation).'
                           }
  
                      },
                      wcs:{
                          title:"SAGA WCS",
                          sub:"SAGA WCS (Warehouse Control System) is a responsive online tool connected to SAGA WMS, important for controlling, monitoring and decision-making in your company's logistical processes. With SAGA WCS, manage your operation remotely and always have reception, dispatch, occupation, productivity, goals, and other information about the operation at hand.",
                          subtitle:"MAIN FEATURES",
                          controle:  {
                              title: 'OPERATION CONTROL',
                              text:  "It monitors the productivity of the shipping process according to services, orders, parts, UMA's and others. The operation summary such as ungenerated orders, daily production and running services."
                           },
                           ranking:  {
                              title: 'OPERATOR RANKING',
                              text:  "Productivity analysis by activity being monthly, daily or in real time by operator. It is possible to analyze by services, parts, orders, weight, volume and others. It can be displayed on TV's or monitors in the operation itself."
                           },
                           mapa:  {
                              title: 'WAREHOUSE MAP',
                              text:  "Through a dynamic view it is possible to have the values of warehouse occupancy, physical space of addresses, occupancy by products/groups/regions and even suppliers. Allows you to view blocked or close to expiration stocks, segregated by blockages, addresses with services, stocktaking and much more."
                           }
                          
                      },
                      wcsLight:{
                          title:"WMS - SAGA LIGHT",
                          sub:"SAGA LIGHT is a version of the WMS with the logistical processes of Receiving, Dispatch, Inventory, Resupply and Transfer.",
                          subtitle:"MAIN FEATURES",
                          subti: "NOVO SAGA LIGHT is a system aimed at companies that need a WMS with license and low cost implementation, being vertical and specialist.",
                          rapida:  {
                              title: "FAST IMPLEMENTATION ",
                              text:  "The system allows for a leaner implementation, being faster and more objective."
                           },
                           reducao:  {
                              title: "COST REDUCTION",
                              text:  "With SAGA you can equate the various resources of the operation (labor, mobile devices, storage regions) maximizing your results through the use of active convocationn, centralized architecture and graphical mode. SAGA works to save your logistics for the future of your company."
                           },
                           convocacao:  {
                              title: "ACTIVE CONVOCATION",
                              text:  "The system directs tasks to employees on an ongoing basis, respecting prioritization and performance rules. The services look for the operators and not the other way around, setting their rhythm to the operation. The Active Convocation avoids idleness, increasing productivity in the SAGA WMS, which is a great operational differential and superior performance to the operation."
                           },
                           custo:  {
                            title: "COST X BENEFIT",
                            text:  "Expert WMS optimized with the main features of Advanced. Perfectly meets the receiving, shipping, resupply and inventory processes."
                         },
                           monitoria:  {
                              title: "MONITORING AND PRODUCTIVITY",
                              text:  "It is an essential tool in the analysis and comparison of teams, individuals and warehouses, providing a complete set of performance indicators (KPIs), providing metrics used in various variable compensation methodologies (performance evaluation)."
                           }
                      },
                      wmsCloud:{
                          title:"Saga – cloud",
                          sub:"With SAGA CLOUD you can now have all your SAGA operations in the cloud. Manage from wherever you are safely and have guaranteed functionality and complete control of your warehouse. Optimize deployment time and reduce the need to invest in new servers.",
                          subtitle:"MAIN FEATURES",
                          subti: "The NEW SAGA Cloud allows the customer to acquire a specialist and vertical WMS in a service model, reducing costs.",
                          menor:  {
                              title: "LOWER INFRASTRUCTURE COST",
                              text:  "The user does not have to worry about the operating system, hardware, servers and network that he is using on his personal computer, being able to access his data in the “cloud computing”, regardless of that."
                           },
                           convocacao:  {
                              title: "ACTIVE CONVOCATION",
                              text:  "The system directs tasks to employees on an ongoing basis, respecting prioritization and performance rules. The services look for the operators and not the other way around, setting their rhythm to the operation. The Active Convocation avoids idleness, increasing productivity in the SAGA WMS, which is a great operational differential and superior performance to the operation."
                           },
                           mapeamento:  {
                              title: "RESOURCE MAPPING",
                              text:  "Resource tracking allows SAGA WMS to optimize the operation, promoting a reduction in movements and productivity control."
                           },
                           monitoria:  {
                              title: "MONITORING AND PRODUCTIVITY",
                              text:  "It is an essential tool in the analysis and comparison of teams, individuals and warehouses, providing a complete set of performance indicators (KPIs), providing metrics used in various variable compensation methodologies (performance evaluation)."
                           }
                      },
                      wmsInv:{
                        title:"SAGA INV ",
                        sub:"Saga INV is a tool that was created in order to provide practicality in inventory operations without the need to control stored stock.Starting the counts from a spreadsheet or sending data via the web interface. The INV also works completely autonomously, where with a few clicks it is possible to start the counts and send them to the operators.Without the need to use sophisticated equipment, INV was designed to work on devices with the Android system and easily accessible, such as cell phones and tablets.",
                        btn:"CUSTOMER'S AREA"
                    }   
                     
                      
                        
                    }
                  },
                partners:{
                    title:"PARTNERS",
                    Subtitle:"PARTNERS - SAGA TECNOLOGIA",
                    btn:"BE PARTNER",
                    btnhomo:"Homologated pocket devices",
                },
                termo:{
                    part1:"By using this site you agree to the",
                    part2:"terms of use and privacy policy",
                    part3:"of SAGA TECHNOLOGY.",
                    concordo:"I AGREE",
                },
                ouvidoria:
                {
                    title:"Ombudsman",
                    name:"Name",
                    contato:"Contact",
                    mensagem:"Message"
                }  
            },
            
            components: {
                navBar: {
                    home: 'HOME',
                    about: 'ABOUT',
                    contacts: 'CONTACT',
                    produtos: "PRODUCTS",
                    suporte: "SUPPORT"
                },
                footer: {
                    trabalhe:"WORK WITH US", 
                    sobre:{
                        title: 'ABOUT SAGA',
                        listItem:{
                            text: {
                                text: "To attend a great diversity of business segments involved with logistics and management issues, our products seek the greatest possible versatility, considering the diversity of existing processes."
                            }
                        }
                    },
                    contato:{
                        title: 'CONTACT',
                        listItem:{
                            ende: {
                                text: "Address:",
                                sub: "Av. Raja Gabáglia, 3117 - Sl. 224 Bairro São Bento - Belo Horizonte / MG - Cep: 30350-563"
                            },
                            email: {
                                text: "E-mail:",
                                sub: "sacom@sasistemas.com.br"
                            },
                            tele: {
                                text: "Phone:",
                                sub: "+55 31 4501-0000"
                            },
                            skyp: {
                                text: "Skype:",
                                sub: "carol.salles06"
                            },
                            what: {
                                text: "Whatsapp:",
                                sub: "+55 31 99315-3709"
                            }
                        }
                    },
                    saibaMais:{
                        title: 'LEARN MORE',
                        listItem:{
                            saiba: {
                                text: "Want to meet us? Register your e-mail."
                            }
                        }
                    },
                },
                RelatedServices:{
                    title:"RELATED SERVICES",
                    supo:{
                        title:"HELP dESK",
                        text:"Saga Tecnologia has a great help desk team serving its customers whenever necessary to give continuity to the processes of each company."
                    },
                    asse:{
                        title:"TECHNICAL ASSISTANCE",
                        text:"We can offer software changes aiming at specific adjustment and service for some functionality according to the customer's need."
                    },
                    trei:{
                        title:"TRAINING",
                        text:"We offer training in the use of SAGA according to the needs of each company."
                    },
                    impl:{
                        title:"IMPLEMENTATION AND CONSULTANCY",
                        text:"Professionals trained to implement your software with know-how to assist in the definitions aimed at your company."
                    },

                },
                topbar:{
                    Acessibilidade: "Accessibility:"
                },
                

                Suporte:{
                    text:"Our team of help desk and maintenance has the highest priority of service quality, we value the high operational performance of our products.With specialized analysts for each customer, we have a team with a high level of technical knowledge for all products in the Saga Tecnologia line.The difference between the support options is simply the opening hours of each option, with Gold support offering 24 hours a day, seven days a week, uninterrupted service. Silver support, we provide personalized service, according to the customer's operating hours and Bronze support, the service is done during business hours.For any operational issues or questions please contact Saga support. We have a professional ready to assist you quickly and safely so that you do not interrupt your operation.",
                    title:"MODALITY",
                    bronze:"BRONZE",
                    prata:"SILVER",
                    ouro:"GOLD",
                    tr1:"HELP DESK BUSINESS HOURS",
                    tr2:"HELP DESK PERSONALIZED HOUR",
                    tr3:"HELP DESK UNINTERRUPTED (24X7)",
                    tr4:"SPECIALIZED ANALYSTS FOR EACH CUSTOMER WITH BACKUP ANALYSTS",
                    tr5:"CUSTOMER MANAGER",
                    tr6:"ADHERE TO INDIVIDUAL CUSTOMIZATIONS",
                    tr7:"CONTINUOUS AND UNLIMITED EVOLUTION",
                    tr8:"OPERATIONAL SUPPORT FRANCHISE THROUGH IT OWN CONTRACT",
                    tr9:"UNLIMITED DEDUCTIBLE FOR FAULT CALLS",
                }
                
            }
        }
    }
}

export { messages }