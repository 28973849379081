import React from "react";
import SubTitle from "../../../../components/SubTitle";
import treinamento from '../../../../images/products/servicos-relacionados/book.svg';
import consultoria from '../../../../images/products/servicos-relacionados/chalkboard-teacher.svg';
import acessoria from '../../../../images/products/servicos-relacionados/desktop.svg';
import suporteTecnico from '../../../../images/products/servicos-relacionados/headset.svg';
import { i18n } from "../../../../translate/i18n";

interface IItemServiceProps{
    icon: string;
    title: string;
    text: string;
}

const ItemService: React.FC<IItemServiceProps> = ({icon, title, text}) => {
    return (
        <div className="col-md-3 col-sm-12">
            <div style={{background: '#F5F5F5', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', minHeight: '280px', padding: '.5rem', borderRadius: '.2rem'}}>
                <img src={icon} alt="" style={{padding: '.8rem',borderRadius: '10rem', background: '#752C2D'}} />
                <h6 style={{textTransform: 'uppercase', fontFamily: 'Roboto', margin: '5px 0', padding: '0', letterSpacing: '.05rem'}}>{title}</h6>
                <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>{text}</p>
            </div>
        </div>
    )
}

const RelatedServices = () => {
    return(
        <section className="container" style={{margin: '40px auto'}}>
            <SubTitle text={i18n.t('components.RelatedServices.title').toString()} />
            <div className="row text-center">
                <ItemService icon={suporteTecnico} title={i18n.t('components.RelatedServices.supo.title').toString()} text= {i18n.t('components.RelatedServices.supo.text').toString()} />
                <ItemService icon={acessoria} title={i18n.t('components.RelatedServices.asse.title').toString()} text={i18n.t('components.RelatedServices.asse.text').toString()} />
                <ItemService icon={treinamento} title={i18n.t('components.RelatedServices.trei.title').toString()} text={i18n.t('components.RelatedServices.trei.text').toString()}/>
                <ItemService icon={consultoria} title={i18n.t('components.RelatedServices.impl.title').toString()} text={i18n.t('components.RelatedServices.impl.text').toString()} />
            </div>
        </section>
    );
}

export default RelatedServices;