import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

interface ICheckBoxSagaWmsProps{
    text: string;
}

const CheckBoxSagaWms: React.FC<ICheckBoxSagaWmsProps> = ({text}) => {
    return (
        <div style={{padding: '.5rem 2rem'}}>
            <FontAwesomeIcon icon={faCheckSquare} color="#752C2D" size="2x" />
            <label style={{fontFamily: 'Open Sans', marginLeft: '.7rem', fontSize: '1rem', textTransform: 'capitalize'}}>{text}</label>
        </div>
    );
}

export default CheckBoxSagaWms;