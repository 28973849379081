import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Footer from '../../components/Footer';
import ListContacts from '../../components/ListContacts';
import NavBar from '../../components/NavBar';
import SubTitle from '../../components/SubTitle';
import TopBar from '../../components/TopBar';
import logo from '../../images/logo.png';
import bgQuote from '../../images/quote-contato.jpg';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';

interface IContact{
  name?: string;
  email?: string;
  phone?: string;
  nameCompany?: string;
  city?: string;
  state?: string;
  message?: string;
}

const Contact = () => {
  const styleH3: React.CSSProperties = {fontFamily: 'Roboto', letterSpacing: '.1rem', fontSize: '1.4rem', textTransform: 'uppercase'}
  const [contact, setContact] = useState<IContact>();
  const [recaptchaValidated, setRechaptchaValidated] = useState<boolean>(false);
  return (
    <>
      <TopBar />
      <section className="container">
        <img src={logo} alt="Logo SAGA TECNOLOGIA" className="logo" />
        <NavBar />
        <div className="row" style={{marginTop: '40px'}}>
          <SubTitle text={i18n.t('page.contact.title').toString()} />
          <div className="col-md-8 col-sm-12">
            <h3 style={styleH3}>{i18n.t('page.contact.listItem.textoInicial.text').toString()}</h3>
            <p style={{fontFamily: 'Open Sans'}}>{i18n.t('page.contact.listItem.textoInicial.subTi').toString()}</p>
            <form 
              style={{fontFamily: 'Open Sans'}} 
              onSubmit={async (e) => {
                e.preventDefault();
                if(contact && recaptchaValidated){
                  const retorno=
                  await api.post('/formulario/contato', contact);
                  if(retorno.status === 200)
                  {
                      alert(i18n.t('page.contact.alert').toString());
                  }
                }
                }}>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <input 
                    placeholder={i18n.t('page.contact.listItem.info.name').toString()} 
                    className="form-control" 
                    required 
                    value={contact?.name} 
                    onChange={(e) => {
                      setContact({...contact, name: e.target.value})
                    }} />
                </div>
                <div className="col-md-4 col-sm-12">
                  <input 
                    placeholder={i18n.t('page.contact.listItem.info.emai').toString()} 
                    className="form-control" 
                    required 
                    type="email" 
                    value={contact?.email} 
                    onChange={(e) => {
                      setContact({...contact, email: e.target.value})
                    }} />
                </div>
                <div className="col-md-4 col-sm-12">
                  <input 
                    placeholder={i18n.t('page.contact.listItem.info.tele').toString()} 
                    className="form-control" 
                    required
                    type="tel" 
                    value={contact?.phone} 
                    onChange={(e) => {
                      setContact({...contact, phone: e.target.value})
                    }} />
                </div>
              </div>
              <div className="row" style={{padding: '20px 0'}}>
                <div className="col-md-4 col-sm-12">
                  <input 
                    placeholder={i18n.t('page.contact.listItem.info.empr').toString()} 
                    className="form-control" 
                    required 
                    value={contact?.nameCompany} 
                    onChange={(e) => {
                      setContact({...contact, nameCompany: e.target.value})
                    }} />
                </div>
                <div className="col-md-4 col-sm-12">
                  <input 
                    placeholder={i18n.t('page.contact.listItem.info.cida').toString()} 
                    className="form-control" 
                    required 
                    value={contact?.city} 
                    onChange={(e) => {
                      setContact({...contact, city: e.target.value})
                    }} />
                </div>
                <div className="col-md-4 col-sm-12">
                  <input 
                    placeholder={i18n.t('page.contact.listItem.info.esta').toString()} 
                    className="form-control" 
                    required 
                    value={contact?.state} 
                    onChange={(e) => {
                      setContact({...contact, state: e.target.value})
                    }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <textarea 
                    placeholder={i18n.t('page.contact.listItem.info.mens').toString()} 
                    className="form-control" 
                    required 
                    value={contact?.message} 
                    onChange={(e) => {
                      setContact({...contact, message: e.target.value})
                    }} />
                </div>
              </div>
              <div style={{float: 'right', marginTop: '10px'}}>
                <button disabled={!recaptchaValidated} type="submit" className="btn" style={{background: '#752C2D', color: '#FFF', letterSpacing: '.1rem', fontFamily: 'Roboto'}}>{i18n.t('page.contact.listItem.info.btne').toString()}</button>
              </div>
              <div className="row">
                <div style={{float: 'right', marginTop: '10px'}}>
                  <ReCAPTCHA
                      sitekey="6LcsOkAUAAAAAHtKlobwzh0hhl-DgToUhC-95ZAJ"
                      onChange={(v) => { setRechaptchaValidated(true); }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-4 col-sm-12">
            <h3 style={styleH3}>{i18n.t('page.contact.info').toString()}</h3>
            <ListContacts color="#424242" paddingTop="1rem"  />
          </div>          
        </div>
      </section>
      <section style={{marginTop: '40px', height: '200px', background: `url(${bgQuote})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>        
      </section>
      <Footer />
    </>
  );
}

export default Contact;