import React from "react";

interface IItemFeatureProps{
    img: string;
    title: string;
    text: string;
}

const ItemFeature: React.FC<IItemFeatureProps> = ({img, title, text}) => {
    return(
        <div className="col-md-6 col-sm-12">
            <img src={img} alt="Icone SAGA TECNOLOGIA" width="35px" />
            <span style={{fontSize: '1.1rem', paddingLeft: '.5rem', letterSpacing: '.05rem', fontFamily: 'Roboto', color: '#424242'}}>{title}</span>
            <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>{text}</p>
        </div>
    )
}

export default ItemFeature;