import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import TopBar from "../../components/TopBar";
import logo from "../../images/logo.png";
//aqui tem q ter 20
import artico from "../../images/partners/logo-grupoart.png";
import intermec from "../../images/partners/intermec.jpg";
import infovix from "../../images/partners/infovix.jpg";
import boreal from "../../images/partners/boreal.jpg";
import lxe from "../../images/partners/lxe.jpg";
import honeywell from "../../images/partners/honeywell.jpg";
import opticon from "../../images/partners/opticon.jpg";
import systada from "../../images/partners/systada.jpg";
import vetta from "../../images/partners/vetta.jpg";
import planner from "../../images/partners/planner.jpg";
import emplaca from "../../images/partners/emplaca.jpg";
import hg_code from "../../images/partners/hg_code.jpg";
import motorola2 from "../../images/partners/motorola2.jpg";
import compex from "../../images/partners/compex.jpg";
import sint_sistemas from "../../images/partners/sint_sistemas.jpg";
import zebra from "../../images/partners/zebra.jpg";
import hilux from "../../images/partners/hilux.jpg";
import rr_etiquetas from "../../images/partners/rr_etiquetas.jpg";
import vallid from "../../images/partners/vallid.jpg";
import { Col, Row } from "reactstrap";
import SubTitle from "../../components/SubTitle";
import { i18n } from "../../translate/i18n";
import { Link } from "react-router-dom";

const partners = () => {
  return (
    <>
      <TopBar />
      <div className="container">
        <img src={logo} alt="Logo Saga Tecnologia" className="logo" />
        <NavBar />
      </div>
      <div style={{ margin: " 2rem 0 2rem" }}>
        <SubTitle text={i18n.t("page.partners.Subtitle")} />
      </div>
      <div style={{ textAlign: "center", margin: "5rem 0 3rem 0" }}>
        <Link
          to="/contact"
          className="btn btn-lg"
          style={{
            margin: "0 auto",
            background: "#752C2D",
            color: "#FFF",
            display: "flex",
            width: "220px",
            justifyContent: "center",
            alignItems: "center",
          }}
          rel="noreferrer"
        >
          {i18n.t("page.partners.btn").toString()}
        </Link>
      </div>

      <Row>
        <Row style={{ textAlign: "center" }}>
          <Col md="3" sm="12">
            <a
              data-title="Grupo Artico<br>"
              href="https://www.grupo-artico.com/"
              target="blank"
            >
              <div>
                <img src={artico} alt="" width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <div>
              <img src={intermec} alt="" width={"100%"} />
            </div>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Infovix<br>"
              href="http://www.inforvix.com.br/"
              target="blank"
            >
              <div>
                <img src={infovix} alt="" width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Borealtech<br>"
              href="http://www.borealtech.com.br/"
              target="blank"
            >
              <div>
                <img src={boreal} alt="" width={"100%"} />
              </div>
            </a>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col md="3" sm="12">
            <a
              data-title="LXE<br>"
              href="http://www.honeywellaidc.com/pages/SiteLanguages.aspx"
              target="blank"
            >
              <div>
                <img src={lxe} alt="" width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Honeywell<br>"
              href="http://www.honeywellaidc.com/pages/SiteLanguages.aspx"
              target="blank"
            >
              <div>
                <img src={honeywell} alt="" width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <div>
              <img src={opticon} alt=" " width={"100%"} />
            </div>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Sysdata<br>"
              href="http://www.sysdatanet.com.br/"
              target="blank"
            >
              <div>
                <img src={systada} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col md="3" sm="12">
            <a
              data-title="Vetta<br>"
              href="http://www.vetta.com.br"
              target="blank"
            >
              <div>
                <img src={vetta} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Planner<br>"
              href="http://www.plannernet.com.br/"
              target="blank"
            >
              <div>
                <img src={planner} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Emplaca<br>"
              href="http://www.emplaca.com.br/"
              target="blank"
            >
              <div>
                <img src={emplaca} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Hg Code<br>"
              href="http://www.hgcode.com.br/ "
              target="blank"
            >
              <div>
                <img src={hg_code} alt="" width={"100%"} />
              </div>
            </a>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col md="3" sm="12">
            <a
              data-title="Motorola<br>"
              href="http://www.symbolrepairparts.com/?gclid=CjwKEAjw1Iq6BRDY_tK-9OjdmBESJABlzoY7RIP5p6zhhmBdj-gVeaw2zt6_Sjbp2unT1PjjpGpKYRoCw7Tw_wcB"
              target="blank"
            >
              <div>
                <img src={motorola2} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Compex<br>"
              href="http://www.compextec.com.br/"
              target="blank"
            >
              <div>
                <img src={compex} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <a data-title="Sint<br>" href="http://sint.com.br/" target="blank">
              <div>
                <img src={sint_sistemas} alt="" width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="3" sm="12">
            <a
              data-title="Zebra<br>"
              href="https://www.zebra.com/br/pt.html"
              target="blank"
            >
              <div>
                <img src={zebra} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col md="4" sm="12">
            <a
              data-title="Hilux Tecnologia<br>"
              href="http://www.hiluxtecnologia.com.br/"
              target="blank"
            >
              <div>
                <img src={hilux} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="4" sm="12">
            <a
              data-title="RR Etiquetas<br>"
              href="http://www.rretiquetas.com.br/"
              target="blank"
            >
              <div>
                <img src={rr_etiquetas} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
          <Col md="4" sm="12">
            <a
              data-title="Vallid<br>"
              href="http://www.vallid.com.br/"
              target="blank"
            >
              <div>
                <img src={vallid} alt=" " width={"100%"} />
              </div>
            </a>
          </Col>
        </Row>
      </Row>
      <div style={{ margin: " 1rem 0 2rem" }}>
        <a
          href="../../images/ListaColetoresHomologado.pdf"
          target="_blank"
          className="btn btn-lg"
          style={{
            margin: "0 auto",
            background: "#752C2D",
            color: "#FFF",
            display: "flex",
            width: "300px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {i18n.t("page.partners.btnhomo").toString()}
        </a>
      </div>
      <Footer />
    </>
  );
};
export default partners;
