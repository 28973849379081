import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { i18n } from '../../translate/i18n';

interface INavBarProps{
  color?: string;
}

const NavBar: React.FC<INavBarProps> = ({color}) => {
  return (
      <>
        <nav id="navBar" style={{float: 'right', marginTop: '1rem'}}>
            <Link style={{color: color || '#424242'}} to="/">{i18n.t('components.navBar.home').toString()}</Link>
            <Link style={{color: color || '#424242'}} to="/about">{i18n.t('components.navBar.about').toString()}</Link>
            <Link style={{color: color || '#424242'}} to="/partners">{i18n.t('page.partners.title').toString()}</Link>
            <div className="dropdown">
                <span style={{color: color || '#424242'}}>{i18n.t('components.navBar.produtos').toString()}</span>
                <div className="dropdown-content">
                  <Link style={{color: '#424242', padding: '5px 0', display: 'block'}} to="/products/wms-advanced">WMS ADVANCED</Link>
                  <Link style={{color: '#424242', padding: '5px 0', display: 'block'}} to="/products/wms-cloud">WMS CLOUD</Link>
                  <Link style={{color: '#424242', padding: '5px 0', display: 'block'}} to="/products/wms-light">WMS LIGHT</Link>
                  <Link style={{color: '#424242', padding: '5px 0', display: 'block'}} to="/products/wcs">WCS</Link>
                  <Link style={{color: '#424242', padding: '5px 0', display: 'block'}} to="/products/inv">SAGA INV</Link>
                </div>
            </div>
            <Link style={{color: color || '#424242'}} to="/contact">{i18n.t('components.navBar.contacts').toString()}</Link>
            <Link style={{color: color || '#424242'}} to="/support">{i18n.t('components.navBar.suporte').toString()}</Link>
            

        </nav>
        <span style={{color: color || '#424242'}} id="burger-icon" onClick={() => {
          let x = document.getElementById("navBar") as HTMLElement;
          if (x.className === "topnav") {
            x.className += " responsive";
          } else {
            x.className = "topnav";
          }
        }}>
          <FontAwesomeIcon icon={faBars} size="3x" />
        </span>
      </>
  );
}

export default NavBar;
