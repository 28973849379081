import React from 'react';

interface ISubTitleProps{
    text: string;
    color?: string;
}

const SubTitle: React.FC<ISubTitleProps> = ({text, color}) => {
  return (
      <h2 style={{color: color || '#424242'}}>{text}</h2>
  );
}

export default SubTitle;
