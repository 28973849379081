import NavBar from "../../../components/NavBar";
import TopBar from "../../../components/TopBar";
import logo from '../../../images/logo.png';
import Footer from "../../../components/Footer";
import SubTitle from "../../../components/SubTitle";
import ItemFeature from "../components/ItemFeature";
import RelatedServices from "../components/RelatedServices";
import img from '../../../images/products/wmsLight.png';
import iconConvocacaoAtiva from '../../../images/products/convocacao-ativa.png';
import custo from '../../../images/products/custo-benef.png';
import rapida from '../../../images/products/rapida.png';  
import monitoria from '../../../images/products/monitoria.png';
import reducao from '../../../images/products/reducao.png';
import { i18n } from "../../../translate/i18n";
import GoToAction from "../components/GoToAction";




const WmsLight = () => {
    return(
        <>
            <TopBar/>
            <div className="container">
                <img src={logo} alt="Logo Saga Tecnologia" className="logo" />
                <NavBar />
            </div>
            <section className="container" style={{margin: '40px auto'}}>
                <SubTitle text={i18n.t('page.products.itens.wcsLight.title').toString()} />
                <div className="row" style={{marginTop: '20px'}}>
                    <div className="col-md-7 col-sm-12">
                        <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>
                        {i18n.t('page.products.itens.wcsLight.sub').toString()}
                        </p>
                        <GoToAction />
                    </div>    
                    <div className="col-md-5 col-sm-12">
                        <img src={img} alt="SAGA WMS ADVANCED" width="100%" />
                    </div>
                </div> 
            </section>
            <section style={{background: '#F5F5F5', padding: '10px 0'}}>
                <SubTitle text={i18n.t('page.products.itens.wcsLight.subtitle').toString()} />
                
                <div className="container">
                <div className="row" style={{textAlign:'center'}}>
                <p style={{fontFamily: 'Open Sans'}}>{i18n.t('page.products.itens.wcsLight.subti').toString()}</p>
                </div>
                    <div className="row" style={{padding: '10px 0 '}}>
                        <ItemFeature img={rapida} title={i18n.t('page.products.itens.wcsLight.rapida.title').toString()} text={i18n.t('page.products.itens.wcsLight.rapida.text').toString()} />
                        <ItemFeature img={custo} title={i18n.t('page.products.itens.wcsLight.custo.title').toString()} text={i18n.t('page.products.itens.wcsLight.custo.text').toString()}/>
                    </div>    
                    <div className="row" >
                        <ItemFeature img={reducao} title={i18n.t('page.products.itens.wcsLight.reducao.title').toString()} text={i18n.t('page.products.itens.wcsLight.reducao.text').toString()} />
                        <ItemFeature img={iconConvocacaoAtiva} title={i18n.t('page.products.itens.wcsLight.convocacao.title').toString()} text={i18n.t('page.products.itens.wcsLight.convocacao.text').toString()} />
                    </div>
                    <div className="row" >
                        <ItemFeature img={monitoria} title={i18n.t('page.products.itens.wcsLight.monitoria.title').toString()} text={i18n.t('page.products.itens.wcsLight.monitoria.text').toString()} />
                    </div>
                </div>
            </section>

            <RelatedServices />
            <Footer />
        </>
    ) 
}

export default WmsLight;