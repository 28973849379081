import axios from "axios";

const api = axios.create({
  baseURL: "https://sagatecnologia.com.br:8443/api",
});

api.interceptors.request.use(async config => {
  return config;
});

export default api;