
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarked, faPhone } from '@fortawesome/free-solid-svg-icons';
import { i18n } from '../../translate/i18n';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import whatsappIcon from '../../images/whatsapp.svg';
import linkedInIcon from '../../images/linkedIn.svg';
import bullhornIcon from '../../images/bullhorn.svg';
import faceIcon from '../../images/facebook.svg';
import instIcon from '../../images/inst.svg';
import tv from '../../images/tv.svg';
import suporteIcon from '../../images/suporte.svg';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '../../services/api';



interface IListContactsProps{
    color: string;
    paddingTop: string;
    fontSize?: string;
}
interface IWorkWithUs{
    name?: string;
    area?: string;
    presentation?: string;
    file?: File;    
}


const ListContacts: React.FC<IListContactsProps> = ({color, paddingTop, fontSize}) => {
    const styleRodapeContato = {
        fontWeight: '500', 
        padding: `0 .2rem`
    };
    const styleIcon = { 
        background: '#752C2D', 
        padding: '.3rem', 
        borderRadius: '16px', 
        marginRight: '.5rem' 
      };
      const [isOpen, setIsOpen] = useState<boolean>(false);
      const [workWithUs, setWorkWithUs] = useState<IWorkWithUs>();
    return(
        <ul style={{listStyle: 'none', margin: '0', padding: '0', fontSize: fontSize || '12px', fontFamily: 'Open Sans', fontWeight: '400', color: color}}>
            <li style={{paddingTop: paddingTop}}>
                <FontAwesomeIcon icon={faMapMarked} />
                <b style={styleRodapeContato}>{i18n.t('components.footer.contato.listItem.ende.text').toString()}</b> <a href="https://goo.gl/maps/rp1usr5vfpivrXY4A" style={{color: color || '#F5F5F5'}} target="_blank" rel="noreferrer"> {i18n.t('components.footer.contato.listItem.ende.sub').toString()} </a>
            </li>
            <li style={{paddingTop: paddingTop}}>
                <FontAwesomeIcon icon={faEnvelope} />
                <b style={styleRodapeContato}> {i18n.t('components.footer.contato.listItem.email.text').toString()} </b> <a href="mailto:sacom@sasistemas.com.br" style={{color: color || '#F5F5F5'}} target="_blank" rel="noreferrer"> {i18n.t('components.footer.contato.listItem.email.sub').toString()}</a>
            </li>
            <li style={{paddingTop: paddingTop}}>
                <FontAwesomeIcon icon={faPhone} />
                <b style={styleRodapeContato}>{i18n.t('components.footer.contato.listItem.tele.text').toString()}</b> <a href="tel:5503145010000" style={{color: color || '#F5F5F5'}} target="_blank" rel="noreferrer"> {i18n.t('components.footer.contato.listItem.tele.sub').toString()} </a>
            </li>
            <li style={{paddingTop: paddingTop}}>
                <FontAwesomeIcon icon={faPhone} />
                <b style={styleRodapeContato}>{i18n.t('components.footer.contato.listItem.skyp.text').toString()}</b><a href="https://join.skype.com/tyTckZ5q8wXa" style={{color: color || '#F5F5F5'}} target="_blank" rel="noreferrer"> {i18n.t('components.footer.contato.listItem.skyp.sub').toString()} </a>
            </li>
            {
            <li style={{paddingTop: paddingTop}}>
                <FontAwesomeIcon icon={faPhone} />
                <b style={styleRodapeContato}>{i18n.t('components.footer.contato.listItem.what.text').toString()}</b> <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5531993153709" style={{color: color || '#F5F5F5'}} target="_blank" rel="noreferrer">  {i18n.t('components.footer.contato.listItem.what.sub').toString()} </a>
            </li>
            }
            <li style={{paddingTop: paddingTop, margin: "1.5rem 0"}}>
            <div>
                {
            <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5531993153709" style={{marginLeft:"1rem"}} target="_blank" rel="noreferrer">
              <i style={styleIcon}>
                <img src={whatsappIcon} alt="Whatsapp" width="18px" height="35px"/>
              </i>
            </a>
            }
            <a href="https://www.instagram.com/saga.tecnologia/?igshid=YmMyMTA2M2Y=" style={{marginLeft:"1rem"}} target="_blank" rel="noreferrer">
              <i style={styleIcon}>
                <img src={instIcon} alt="inst" width="18px" height="35px"/>
              </i>
            </a>
            <a href="https://www.linkedin.com/company/saga-tecnologia/" style={{marginLeft:"1rem"}} target="_blank" rel="noreferrer">
              <i style={styleIcon}>
                <img src={linkedInIcon} alt="LinkedIn" width="18px" height="35px"/>
              </i>
            </a>
            <span style={{cursor: 'pointer',marginLeft:"1rem"}} onClick={() => { setIsOpen(true)}} >
              <i style={styleIcon} >
                <img src={bullhornIcon} alt="Bulhorn" width="18px" height="35px"/>
              </i>
            </span>
            <a href="https://servidorseguro.mysuite1.com.br/client/login.php?sl=saga&param=hd_chat_gc_cad_chatdep&cdu=&inf=&sl=saga&lf=&ca=&cr=&idm=&cdu=1&redirect=http://saga.mysuite1.com.br/empresas/saga/central.php" style={{marginLeft:"1rem"}} target="_blank" rel="noreferrer">
              <i style={styleIcon}>
                <img src={suporteIcon} alt="Suporte" width="18px" height="35px"/>
              </i>
            </a>
            <Link style={{color:'#424242' ,marginLeft:"1rem"}} rel="noreferrer" to="/internal">
            <i style={styleIcon} >
                <img src={tv} alt="Suporte" width="18px" height="35px"/>
              </i>
            </Link>
            <Modal isOpen={isOpen}>
        <ModalHeader toggle={() => { setIsOpen(false) }}>
            {i18n.t('page.home.listProducts.trabalho.trabalhe').toString()}
        </ModalHeader>
        <form onSubmit={async (e) => {
            e.preventDefault();
            if(workWithUs && workWithUs.file && isOpen){
                const formData = new FormData();            
                formData.append("file", workWithUs?.file as any);
                formData.append("name", workWithUs?.name || '');
                formData.append("presentation", workWithUs?.presentation || '');
                formData.append("area", workWithUs?.area || '');
                const retorno =
                await api.post('/formulario/trabalhe-conosco', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }});
                    if(retorno.status === 200)
                    {
                        alert(i18n.t('page.contact.alert').toString());
                        setIsOpen( false );
                    }
            }
        }}>
            <ModalBody>
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <input 
                            className="form-control" 
                            placeholder={i18n.t('page.home.listProducts.trabalho.nome').toString()} 
                            value={workWithUs?.name}
                            required
                            onChange={(e) => {
                                setWorkWithUs({...workWithUs, name: e.target.value})
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <select 
                            className="form-control" 
                            placeholder="Área desejada"
                            required
                            value={workWithUs?.area}
                            onChange={(e) => {
                                setWorkWithUs({...workWithUs, area: e.target.value})
                            }}
                        >
                            <option value="Administrativo">{i18n.t('page.home.listProducts.trabalho.adm').toString()}</option>
                            <option value="Comercial">{i18n.t('page.home.listProducts.trabalho.comercial').toString()}</option>
                            <option value="Desenvolvimento/TI">{i18n.t('page.home.listProducts.trabalho.dev').toString()}</option>
                            <option value="Projetos">{i18n.t('page.home.listProducts.trabalho.projetos').toString()}</option>
                            <option value="Suporte">{i18n.t('page.home.listProducts.trabalho.sup').toString()}</option>
                        </select>
                    </div>
                </div>
                <div className="row" style={{padding: '10px 0 0 0'}}>
                    <div className="col-md-12">
                        <textarea 
                            className="form-control" 
                            placeholder={i18n.t('page.home.listProducts.trabalho.apresentacao').toString()}                             
                            value={workWithUs?.presentation}
                            onChange={(e) => {
                                setWorkWithUs({...workWithUs, presentation: e.target.value})
                            }}
                        />
                    </div>
                </div>
                <div className="row" style={{padding: '10px 0 0 0'}}>                    
                    <div className="col-md-12">
                        <input 
                            type="file" 
                            placeholder={i18n.t('page.home.listProducts.trabalho.curriculo').toString()} 
                            className="form-control" 
                            accept=".pdf" 
                            onChange={(e) => {
                                if(e.target.files)
                                    setWorkWithUs({...workWithUs, file: e.target.files[0]})
                            }}
                        />
                    </div>
                    
                </div>
            </ModalBody>
            <ModalFooter>   
                <button type="submit" className="btn btn-secondary">{i18n.t('page.contact.listItem.info.btne').toString()}</button>
            </ModalFooter>
        </form>
    </Modal>
          </div>
            </li>
        </ul>
        
    );
}

export default ListContacts;