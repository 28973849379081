import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SubTitle from "../../../components/SubTitle";
import TopBar from "../../../components/TopBar";
import logo from '../../../images/logo.png';
import ItemFeature from "../components/ItemFeature";
import RelatedServices from "../components/RelatedServices";
import img from '../../../images/products/wcsCloud.png';
import monitoria from '../../../images/products/monitoria.png';
import mapa from '../../../images/products/mapa.png';
import convocacao from '../../../images/products/convocacao-ativa.png'; 
import reducao from '../../../images/products/reducao.png';
import { i18n } from "../../../translate/i18n";
import GoToAction from "../components/GoToAction";



const WmsCloud = () => {
    return(
        <>
            <TopBar/>
            <div className="container">
                <img src={logo} alt="Logo Saga Tecnologia" className="logo" />
                <NavBar />
            </div>
            <section className="container" style={{margin: '40px auto'}}>
                <SubTitle text={i18n.t('page.products.itens.wmsCloud.title').toString()} />
                <div className="row" style={{marginTop: '20px'}}>
                    <div className="col-md-7 col-sm-12">
                        <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>
                        {i18n.t('page.products.itens.wmsCloud.sub').toString()}
                        </p>
                        <GoToAction />
                    </div>    
                    <div className="col-md-5 col-sm-12">
                        <img src={img} alt="SAGA WMS ADVANCED" width="100%" />
                    </div>
                </div> 
            </section>
            <section style={{background: '#F5F5F5', padding: '10px 0'}}>
                <SubTitle text={i18n.t('page.products.itens.wmsCloud.subtitle').toString()} />
                
                <div className="container">
                <div className="row" style={{textAlign:'center'}}>
                <p style={{fontFamily: 'Open Sans'}}>{i18n.t('page.products.itens.wmsCloud.subti').toString()}</p>
                </div>
                    <div className="row" style={{padding: '10px 0 '}}>
                        <ItemFeature img={reducao} title={i18n.t('page.products.itens.wmsCloud.menor.title').toString()} text={i18n.t('page.products.itens.wmsCloud.menor.text').toString()} />
                        <ItemFeature img={convocacao} title={i18n.t('page.products.itens.wmsCloud.convocacao.title').toString()} text={i18n.t('page.products.itens.wmsCloud.convocacao.text').toString()} />
                    </div>    
                    <div className="row" >
                        <ItemFeature img={mapa} title={i18n.t('page.products.itens.wmsCloud.mapeamento.title').toString()} text={i18n.t('page.products.itens.wmsCloud.mapeamento.text').toString()} />
                        <ItemFeature img={monitoria} title={i18n.t('page.products.itens.wmsCloud.monitoria.title').toString()} text={i18n.t('page.products.itens.wmsCloud.monitoria.text').toString()} />
                    </div>
                </div>
            </section>

            <RelatedServices />
            <Footer />
        </>
    ) 
}

export default WmsCloud;