import React from "react";

interface IClientCommentProps{
    image: string;
    comment: string;
    commentedBy: string;
    id: string;
    display?: 'block';
}

const ClientComment: React.FC<IClientCommentProps> = ({image, comment, commentedBy, id, display}) => {
    return (
    <div className="comment-client" id={id} style={{display: display || 'none'}}>
            <img src={image} alt="DEPOIMENTO CLIENTE SAGA TECNOLOGIA" height="200px" width="auto" />
            <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}> {comment} </p>
            <i style={{fontFamily: 'Open Sans', fontWeight: '500'}}> {commentedBy} </i>
    </div>
    );
}

export default ClientComment;