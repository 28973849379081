import { faCheck, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { i18n } from '../../translate/i18n';

const Suporte = () => {
    return(
        <div className="container" style={{fontFamily: 'Open Sans', fontWeight: '300'}}>
            <div >
                <p>     
                {i18n.t('components.Suporte.text').toString()}
                </p>
            </div>
<table className="table table-bordered"  align="center" border={1}  style={{ fontWeight: '500'}} >
   <tbody>
    <tr >
        <td style={{border:'none'}}></td>
    <td colSpan={4} style={{textAlign: 'center'}} >{i18n.t('components.Suporte.title').toString()}</td>
    </tr>
    <tr style={{textAlign: 'center'}}>
        <td></td>
        <td colSpan={1} >{i18n.t('components.Suporte.bronze').toString()}</td>
        <td >{i18n.t('components.Suporte.prata').toString()}</td>
        <td >{i18n.t('components.Suporte.ouro').toString()}</td>
        
    </tr>
    <tr >
        <td>{i18n.t('components.Suporte.tr1').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faRemove} color="#ff0000" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faRemove} color="#ff0000" fontSize={30} /></td>
    </tr>
    <tr>
        <td>{i18n.t('components.Suporte.tr2').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faRemove} color="#ff0000" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faRemove} color="#ff0000" fontSize={30} /></td>
    </tr>
    <tr>
        <td>{i18n.t('components.Suporte.tr3').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faRemove} color="#ff0000" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faRemove} color="#ff0000" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
    </tr>
    <tr>
        <td>{i18n.t('components.Suporte.tr4').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
    </tr>
    <tr>
        <td>{i18n.t('components.Suporte.tr5').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
    </tr>
    <tr>
        <td>{i18n.t('components.Suporte.tr6').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
    </tr>
    <tr>
        <td>{i18n.t('components.Suporte.tr7').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
    </tr>
    <tr>
        <td>{i18n.t('components.Suporte.tr8').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
    </tr>
    <tr>
        <td>{i18n.t('components.Suporte.tr9').toString()}</td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
        <td style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheck} color="#46F08C" fontSize={30} /></td>
    </tr>
    </tbody>
</table>

</div>
    )

}
export default Suporte;