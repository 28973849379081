import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface IHighLightProps {
  icon: IconDefinition;
  title: string;
  subTitle: string;
}

const HighLight: React.FC<IHighLightProps> = ({ icon, title, subTitle }) => {
  return (
    <div
      className="col-md-3"
      style={{
        color: "#FFF",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FontAwesomeIcon icon={icon} size="5x" style={{ marginRight: ".5rem" }} />
      <span style={{ fontSize: "3rem", fontFamily: "Open Sans" }}>{title}</span>
      <p style={{ fontSize: "1rem", fontFamily: "Open Sans" }}>{subTitle}</p>
    </div>
  );
};

export default HighLight;
