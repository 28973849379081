import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SubTitle from "../../../components/SubTitle";
import TopBar from "../../../components/TopBar";
import logo from '../../../images/logo.png';
import img from '../../../images/products/wms-advanced.png';
import iconConvocacaoAtiva from '../../../images/products/convocacao-ativa.png';
import iconFlexibilidade from '../../../images/products/flexibilidade-e-seguranca.png';
import iconMapeamento from '../../../images/products/mapeamento-recursos.png';
import iconMonitoria from '../../../images/products/monitoria.png';
import iconReducaoCusto from '../../../images/products/reducao-custo.png';
import iconRastreabilidade from '../../../images/products/rastreabilidade.svg';
import ItemFeature from "../components/ItemFeature";
import RelatedServices from "../components/RelatedServices";
import { i18n } from "../../../translate/i18n";
import GoToAction from "../components/GoToAction";

const WmsAdvanced = () => {
    return(
    <>
        <TopBar />
        <div className="container">
            <img src={logo} alt="Logo Saga Tecnologia" className="logo" />
            <NavBar />
        </div>
        <section className="container" style={{margin: '40px auto'}}>
            <SubTitle text={i18n.t('page.products.itens.wmsAdvanced.title').toString()} />
            <div className="row" style={{marginTop: '20px'}}>
                <div className="col-md-7 col-sm-12">
                    <p style={{fontFamily: 'Open Sans', fontWeight: '300'}}>
                    {i18n.t('page.products.itens.wmsAdvanced.sub').toString()}
                    </p>
                    <ul style={{margin: '0', fontFamily: 'Open Sans', fontWeight: '300'}}>
                        <li>{i18n.t('page.products.itens.wmsAdvanced.li1').toString()}</li>
                        <li>{i18n.t('page.products.itens.wmsAdvanced.li2').toString()}</li>
                        <li>{i18n.t('page.products.itens.wmsAdvanced.li3').toString()}</li>
                        <li>{i18n.t('page.products.itens.wmsAdvanced.li4').toString()}</li>
                        <li>{i18n.t('page.products.itens.wmsAdvanced.li5').toString()}</li>
                        <li>{i18n.t('page.products.itens.wmsAdvanced.li6').toString()}</li>
                        <li>{i18n.t('page.products.itens.wmsAdvanced.li7').toString()}</li>
                        <li>{i18n.t('page.products.itens.wmsAdvanced.li8').toString()}</li>
                    </ul>
                    <GoToAction />
                </div>
                <div className="col-md-5 col-sm-12">
                    <img src={img} alt="SAGA WMS ADVANCED" width="100%" />
                </div>
            </div>
        </section>
        <section style={{background: '#F5F5F5', padding: '10px 0'}}>
            <SubTitle text={i18n.t('page.products.itens.wmsAdvanced.Sub').toString()} />
            <div className="container">
                <div className="row" style={{textAlign:'center'}}>
                <p style={{fontFamily: 'Open Sans'}}>{i18n.t('page.products.itens.wmsAdvanced.subti').toString()}</p>
                </div>
                </div>
            <div className="container">
                <div className="row">
                    <ItemFeature img={iconConvocacaoAtiva} title={i18n.t('page.products.itens.wmsAdvanced.convocacaoAtiva.title').toString()} text={i18n.t('page.products.itens.wmsAdvanced.convocacaoAtiva.text').toString()} />
                    <ItemFeature img={iconReducaoCusto} title={i18n.t('page.products.itens.wmsAdvanced.reducaoCusto.title').toString()} text={i18n.t('page.products.itens.wmsAdvanced.reducaoCusto.text').toString()}/>                    
                </div>
                <div className="row">
                    <ItemFeature img={iconRastreabilidade} title={i18n.t('page.products.itens.wmsAdvanced.rastreabilidade.title').toString()} text={i18n.t('page.products.itens.wmsAdvanced.rastreabilidade.text').toString()} />
                    <ItemFeature img={iconMapeamento} title={i18n.t('page.products.itens.wmsAdvanced.mapeamento.title').toString()} text={i18n.t('page.products.itens.wmsAdvanced.mapeamento.text').toString()} />                    
                </div>
                <div className="row">
                    <ItemFeature img={iconFlexibilidade} title={i18n.t('page.products.itens.wmsAdvanced.flexibilidade.title').toString()} text={i18n.t('page.products.itens.wmsAdvanced.flexibilidade.text').toString()} />
                    <ItemFeature img={iconMonitoria} title={i18n.t('page.products.itens.wmsAdvanced.Monitoria.title').toString()} text={i18n.t('page.products.itens.wmsAdvanced.Monitoria.text').toString()}/>                    
                </div>
            </div>
        </section>
        <RelatedServices />
        <Footer />
    </>
    );
}

export default WmsAdvanced;