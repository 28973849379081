import React from "react";
import { Col, Row } from "reactstrap";
import arrow from '../../../../images/icons/arrow-double-down.svg';

interface HistoryItemProps {
    icon: string;
    title: string;
    img: string;
    text: string;
    subText?: string;
}

const HistoryItem: React.FC<HistoryItemProps> = ({icon, title, img, text, subText}) => {
    return (
    <div style={{margin: '20px 0'}}>
        <img src={icon} alt="Origem SAGA TECNOLOGIA" style={{padding: '.2rem', borderRadius: '1rem', background: '#752C2D', width: '2rem', height: 'auto'}} />
        <span style={{textTransform: 'capitalize', padding: '0 .8rem', fontSize: '1.2rem', fontFamily: 'Roboto'}}>{title}</span>
        <Row style={{paddingTop: '.3rem'}}>
            <Col md="3" sm="12" xs="12" style={{textAlign: 'center'}}>
                <img src={img} alt="SAGA TECNOLOGIA" width="100%"  />
            </Col>
                
            <Col md="9" sm="12" xs="12" style={{textAlign: 'center'}}>
            <p style={{fontFamily: 'Open Sans', margin: '30px 0 15px 0'}}>{text}</p>
                {subText &&<p style={{fontFamily: 'Open Sans'}}>{subText}</p>}
            </Col>
            <div style={{textAlign: 'center'}}>
            <img src={arrow} alt="SAGA TECNOLOGIA" />
        </div>
        </Row>
    </div>
    )
}

export default HistoryItem;