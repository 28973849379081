import React from 'react';
import { Link } from 'react-router-dom';

interface ICardProductsProps{
    url: string;
    image: string;
    title: string;
    text: string;
}

const CardProducts: React.FC<ICardProductsProps> = ({url, image, title, text}) => {
  return (
    <div className="col-md-3 col-sm-12 col-xs-12 text-center" style={{padding: '0 .7rem'}}>        
        <Link to={`/products/${url}/`}>      
            <img src={image} alt={`${title} - PRODUTO SAGA TECNOLOGIA`} />
            <h3 style={{color: '#752C2D', fontFamily: 'Roboto', fontSize: '1.3rem'}}>{title}</h3>
            <p style={{color: '#424242', fontFamily: 'Open Sans'}}>{text}</p>
        </Link>
    </div>
 
  );
}

export default CardProducts;