import { useEffect, useState } from "react";
import { i18n } from "../../translate/i18n";

const TermPrivacity = () => {
    const [aceitouTermo, setAceitouTermo] = useState<boolean>(false);
    useEffect(() => {
        const jaAceitou = localStorage.getItem('aceitou-termo-privacidade');
        if(jaAceitou)
            setAceitouTermo(true);
    }, []);
    return (
    <div hidden={aceitouTermo} style={{background: '#F1F6F4', padding: '.5rem 0', textAlign: 'center', position: 'fixed', bottom: '0', width: '100%', zIndex: '9999999999', fontFamily: 'Open Sans'}}>
        <p>
            {i18n.t('page.termo.part1').toString()} <a style={{color: '#752C2D'}} target="_blank" href="../../images/termo_privacidade_saga.pdf">{i18n.t('page.termo.part2').toString()}</a> {i18n.t('page.termo.part3').toString()}
        </p>
        <button className="btn btn-secondary m-t-10" onClick={() => {
            localStorage.setItem('aceitou-termo-privacidade', 'ok');
            setAceitouTermo(true);
        }}>
            {i18n.t('page.termo.concordo').toString()}
        </button>
    </div>);
}

export default TermPrivacity;