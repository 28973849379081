import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import api from "../../../../services/api";
import { i18n } from "../../../../translate/i18n";


interface IRequestVisit {
    name?: string;
    email?: string;
    phone?: string;
    nameCompany?: string;
    city?: string;
    state?: string;
    message?: string;
    office?: string;
  }

const GoToAction = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [requestVisit, setRequestVisit] = useState<IRequestVisit>();
    return (
        <>
        <div style={{textAlign: 'center', margin: '10px auto'}}>
            <button className="btn" style={{background: '#752C2D', color: '#FFF'}} onClick={() => {setIsOpen(true);}}>{i18n.t('page.products.itens.btn').toString()}</button>
        </div>
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => { setIsOpen(false)}}>{i18n.t('page.contact.btn').toString()}</ModalHeader>
            <form onSubmit={async (e) => {
                e.preventDefault();
                if(isOpen && requestVisit){
                    const retorno =
                    await api.post('/formulario/visita', requestVisit);
                    if(retorno.status === 200)
                    {
                        alert(i18n.t('page.contact.alert').toString());
                        setIsOpen( false );
                    }
                }
            }}>
                <ModalBody>
                    <div className="row" style={{padding: '5px 0'}}>
                        <div className="col-md-4 col-sm-12">
                            <input 
                                placeholder={i18n.t('page.contact.listItem.info.name').toString()} 
                                className="form-control" 
                                required 
                                value={requestVisit?.name}
                                onChange={(e) => {
                                    setRequestVisit({...requestVisit, name: e.target.value})
                                }}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <input 
                                placeholder={i18n.t('page.contact.listItem.info.emai').toString()} 
                                className="form-control" 
                                required 
                                type="email" 
                                value={requestVisit?.email}
                                onChange={(e) => {
                                    setRequestVisit({...requestVisit, email: e.target.value})
                                }}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <input 
                                placeholder={i18n.t('page.contact.listItem.info.tele').toString()} 
                                className="form-control" 
                                required  
                                value={requestVisit?.phone}
                                onChange={(e) => {
                                    setRequestVisit({...requestVisit, phone: e.target.value})
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{padding: '5px 0'}}>
                        <div className="col-md-6 col-sm-12">
                            <input 
                                placeholder={i18n.t('page.contact.listItem.info.empr').toString()} 
                                className="form-control" 
                                required  
                                value={requestVisit?.nameCompany}
                                onChange={(e) => {
                                    setRequestVisit({...requestVisit, nameCompany: e.target.value})
                                }}
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <input 
                                placeholder={i18n.t('page.contact.listItem.info.office').toString()} 
                                className="form-control" 
                                required 
                                value={requestVisit?.office}
                                onChange={(e) => {
                                    setRequestVisit({...requestVisit, office: e.target.value})
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{padding: '5px 0'}}>                        
                    <div className="col-md-6 col-sm-12">
                        <input 
                            placeholder={i18n.t('page.contact.listItem.info.cida').toString()} 
                            className="form-control" 
                            required
                            value={requestVisit?.city}
                            onChange={(e) => {
                                setRequestVisit({...requestVisit, city: e.target.value})
                            }}
                        />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <input 
                                placeholder={i18n.t('page.contact.listItem.info.esta').toString()} 
                                className="form-control" 
                                required 
                                value={requestVisit?.state}
                                onChange={(e) => {
                                    setRequestVisit({...requestVisit, state: e.target.value})
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{padding: '5px 0'}}>
                        <div className="col-md-12">
                            <textarea 
                                placeholder={i18n.t('page.contact.listItem.info.mens').toString()} 
                                className="form-control" 
                                required  
                                value={requestVisit?.message}
                                onChange={(e) => {
                                    setRequestVisit({...requestVisit, message: e.target.value})
                                }}   
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-secondary">{i18n.t('page.contact.listItem.info.btne').toString()}</button>
                </ModalFooter>
            </form>
        </Modal>
        </>
    );
}

export default GoToAction;